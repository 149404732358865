.form__tabs {
    margin-top: 0;
    margin-bottom: 100px;
    .tabular__carousel__tabs { 
        li {
            a {
                width: 280px; 
                text-align: center;
            }
        }
    }
    .tabular__carousel__content {
        
        &.active {
            padding-top: 60px;
        }
        h2 {
            color: $deep-yellow;
            font-size: 1.6875em;
            margin-top: 50px;
        }
    }
}
#wrapper {
    .form__prices {
        > label {
            display: none;
        }
        ul {
            display: flex;
            align-items: center;
            justify-content: space-between;
            li {
                background-image: none !important;
                
                display: inline-flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                position: relative;
                width: 25%;
                padding: 0 5px !important;
                &:first-child {
                    label {
                        &:before {
                            content: "Turnover of up to $250k";
                        }
                    }
                }
                &:nth-child(2){
                    label {
                        &:before {
                            content: "Turnover of $250k-$1M";
                        }
                    }
                }
                &:nth-child(3){
                    label {
                        &:before {
                            content: "Turnover of $1M-$5M";
                        }
                    }
                }
                &:nth-child(4){
                    label {
                        &:before {
                            content: "Turnover of $5M+";
                        }
                    }
                }
                
                label {
                    width: 100%;
                    height: 200px;
                    background-color: $light-gray;
                    display: block;
                    text-align: left;
                    width: 100%;
                    font-size: 1.9375em;
                    font-weight: 700;
                    border-radius: 6px;
                    padding: 70px 20px 0;
                    color: $deep-gray;
                    &:before {
                        display: block;
                        margin-bottom: 5px;
                        font-size: 16px;
                        text-align: left;
                    }
                    &:after {
                        display: inline-block;
                        position: relative;
                        top: 2px;
                        content: "p/a";
                        font-size: 16px;
                        font-weight: 600;
                        vertical-align: bottom;
                    }
                }
                input:checked + label {
                    background-color: $white;
                    border: solid 1px $light-gray;  
                    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16); 
                }
                input {
                    position: absolute;
                    top: 10px;
                    left: 50%;
                    width: 32px !important;
                    height: 32px !important;
                    margin-top: 10px;
                    margin-left: -16px;
                }
                + li {
                    margin-left: 10px;
                }
            }
        }
    }
}
.form__divider {
    > h3 {
        display: none;
    }
    height: 1px;
    margin: 80px 0 60px;
    border-top: 1px dashed #717171;

}
.wpforms-field {
    position: relative !important;
}
.wpforms-field:not(.form__prices) {
    position: relative;
    margin-bottom: 28px;
}
.wpforms-field.required {
    position: relative;
    &:after {
        content: "*";
        position: absolute;
        top: 0;
        left: 100%;
        display: block;
        padding-left: 2px;
        color: $red;
    }
}
.wpforms-label-hide {
    display: none;
}

.form__tabs {
    input,
    select,
    textarea {
        background-color: $light-gray !important;
    }
}
.form__info {
    > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 0 40px;
        font-style: italic;
        &:before {
            content: " ";
            background: url(../img/icon-info.svg) center center;
            background-size: 24px 24px;
            height: 24px;
            width: 24px;
            display: inline-block;
            margin-right: 10px;
        }
    }
}
.wpforms-one-half {
    width: calc( 50% - 10px);
    display: inline-block;
    &.wpforms-first  {
        margin-right: 20px;
    }
    @include respond-to(medium){
        width: 100%;
        &.wpforms-first  {
            margin-right: 0;
        }
    }
}

.wpforms-submit  {
    border-bottom: solid 4px $deep-yellow;
    border-radius: 4px;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    transition: 0.3s background-color;
    &:hover {
        background-color: $deep-yellow;
    }
    &:after {
        display: none;
    }
}