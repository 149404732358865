.header__top__list--mobile,
.nav__close {
    display: none !important;
    @include respond-to(medium){
        display: flex !important;
    }
}
#burger,
.search--mobile {
    display: none !important;
    @include respond-to(medium){
        display: inline-flex !important;
    }
}
#burger {
    vertical-align: middle;
    padding-left: 27px;
    margin-right: 5px;
    cursor: pointer;
}
.nav__container {
    width: calc(100% - 225px); // 100% minus logo width
    text-align: right;
    @include respond-to(medium){
        width: calc(100% - 100px);
    }
}
.nav__close {
    position: absolute;
    top: 39px;
    right: 10%;
    z-index: 3;
}
.header__nav {
    position: relative;
    text-align: right;
    > ul {
        
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        @include respond-to(medium){
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
        }
        // padding-top: 46px;
        > li {
            position: relative;
            @include respond-to(medium){
                border-left: solid 3px rgba(0,0,0,0);
            }
            // animation 
            // animation-fill-mode: forwards;
            // animation-name: fadeInUpSD;
            // opacity: 0;
            // animation-duration: 1s;
            // --animation-delay: 0.1s;
            // $var: 6;
            // @for $i from 1 through 6 {
            //     &:nth-child(#{$i}) {
            //       animation-delay: calc(var(--animation-delay) * #{$i});
            //     }
            // }
           
            + li {
                margin-left: 36px;
                
            }
            > a {
                font-weight: 500;
                display: block;
                padding-bottom: 20px;
                padding-top: 20px;
                color: $primary;
                font-size: 1.125em;
                position: relative;
                em {
                    font-weight: 400;
                    color: rgba(0,0,0,0.6);
                }
                &:after {
                    content: " ";
                    position: absolute;
                    bottom: 10px;
                    width: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    height: 4px;
                    background-color: $gray;
                    transition: 0.3s width;
                    @include respond-to(medium){
                        display: none;
                    }
                }
                &:hover {
                    text-decoration: none;
                    &:after {
                        width: 100%;
                        @include respond-to(medium){
                            width: 2px;
                            height: 100%;
                        }
                    }
                }
                
            }
            &.active {
                a {
                    &:after {
                        width: 100%;
                    }
                }
            }

            @include respond-to(medium){
                &:hover {
                    border-left: solid 3px $gray;
                }
            }
            &:first-child {
                > a {
                    &:after {
                        background-color: $red;
                    }
                }
                
                @include respond-to(medium){
                    &:hover {
                        border-left: solid 3px $red;
                    }
                    li:hover {
                        background-color: fade-out($red, 0.8);
                    }
                }
            }
            &:nth-child(2) {
                > a {
                    &:after {
                        background-color: $yellow;
                    }
                }
                
                @include respond-to(medium){
                    &:hover {
                        border-left: solid 3px $blue;
                    }
                    li:hover {
                        background-color: fade-out($blue, 0.8);
                    }
                }
            }
            &:nth-child(3) {
                > a {
                    &:after {
                        background-color: $sky;
                    }
                }
                
                @include respond-to(medium){
                    &:hover {
                        border-left: solid 3px $sky;
                    }
                    li:hover {
                        background-color: fade-out($sky, 0.8);
                    }
                }
            }
            &:nth-child(4) {
                > a {
                    &:after {
                        background-color: $aqua;
                    }
                }
                
                @include respond-to(medium){
                    &:hover {
                        border-left: solid 3px $aqua;
                    }
                    li:hover {
                        background-color: fade-out($aqua, 0.8);
                    }
                }
            }
            &:nth-child(5) {
                > a {
                    &:after {
                        background-color: $green;
                    }
                }
                
                @include respond-to(medium){
                    &:hover {
                        border-left: solid 3px $green;
                    }
                    li:hover {
                        background-color: fade-out($green, 0.8);
                    }
                }
            }
            &:nth-child(6) {
                > a {
                    &:after {
                        background-color: $purple;
                    }
                }
                
                @include respond-to(medium){
                    &:hover {
                        border-left: solid 3px $purple;
                    }
                    li:hover {
                        background-color: fade-out($purple, 0.8);
                    }
                }
            }
            &:last-child {
                > a {
                    &:after {
                        background-color: transparent;
                    }
                }
                
            }
            @include respond-to(medium){
                &.nav__dropdown {
                    > a {
                        background: url(../img/icon-chevron-down.svg) no-repeat 90% center;
                        background-size: 24px auto;
                    }
                    &:hover {
                        > a {
                            background: url(../img/icon-chevron-up.svg) no-repeat 90% center;
                        }      
                    }
                }
            }
            
            &.nav__dropdown:hover {
                .dropdown__list {
                    height: 410px;
                    opacity: 1;
                    visibility: visible;
                }
                @include respond-to(medium) {
                    .dropdown__list {
                        height: auto;
                        opacity: 1;
                        visibility: visible;
                        padding: 10px 0;
                    }
                }
            }
            .dropdown__list__desc--default {
                // height: 0;
                // visibility: hidden;
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                width: 50%;
                padding: 32px;
                img {
                    margin-bottom: 31px;
                    max-width: 346px;
                    width: 100%;
                }
                @include respond-to(medium){
                    display: none;
                }
            }
            &.nav__dropdown {
                
                > a:hover  {         
                    + .dropdown__list {
                        .dropdown__list__desc--default {
                                visibility: visible;
                                height: 100%;
                                opacity: 1;
                                background-color: transparent;
                            a {
                                &:before {
                                    // width: 52%;
                                }
                            }
                            
                        }
                        // &--red {
                        //     li:first-child {
                        //         border-left: solid 4px $red;
                        //     }
                            
                        // }
                        // &--yellow {
                        //     li:first-child {
                        //         border-left: solid 4px $yellow;
                        //     }
                            
                        // }
                        // &--green {
                        //     li:first-child {
                        //         border-left: solid 4px $green;
                        //     }
                        // }
                    }
                }
            }
        }
        .dropdown__list {
            position: absolute;
            min-width: 830px;   
            
            overflow: hidden;
            // height:410px;
            background-color: $white;
            padding: 29px 32px 38px 0;
            text-align: left;
            height: 0;
            opacity: 0;
            visibility: hidden;
            transition: all 0.3s;
            right: 0;
            border-radius: 5px;
            box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.15);
            @media only screen and (min-width: 1025px) and (max-width: 1550px) {
                min-width: 700px;
            }
            p {
                overflow: auto;
                max-height: 94px;
            }
            @include respond-to(medium){
                position: relative;
                min-width: 100%;
                width: 100%;
                padding: 0;
                box-shadow: 0px 0px 13px rgba(0, 0, 0, 0);
            }
            &--left {
                left: 0;
                &:before {
                    left: 50px;
                    border-bottom: solid 12px $white !important;
                }
            }
            &--right {
                right: 0;
                &:before {
                    right: 50px;
                    border-bottom: solid 12px $light-gray;
                }
            }

            &:before {
                content: " ";
                position: absolute;
                bottom: 100%;
                border-left: solid 12px transparent;
                border-right: solid 12px transparent;
                height: 0;
                width: 0;
                right: 50px;
                border-bottom: solid 12px $light-gray;
                @include respond-to(medium) {
                    display: none;
                }
            }
            &__desc {
                visibility: hidden;
                opacity: 0;
                border-radius: 0 5px 5px 0;
                height: 0;
                transition: opacity 0.2s;
                position: absolute;
                top: 0;
                right: 0;
                background-color: $light-gray;
                width: 50%;
                z-index: 2;
                padding: 32px;
                img {
                    width: 100%;
                    margin-bottom: 31px;
                }
            }
           
            li {
                border-left: solid 4px transparent;
                box-sizing: border-box;
                border-radius: 3px 0 0 3px;
                transition: 0.6s border-color;
                @include respond-to(medium){
                    border-left: none !important;
                }
                a {
                    width: 100%;
                    display: block;
                    padding: 13px 0 13px 26px;
                    position: relative;
                    z-index: 1;
                    &:before {
                        content: " ";
                        position: absolute;
                        height: 100%;
                        left: 0;
                        width: 0px;
                        top: 0;
                        z-index: 1;
                        background-color: $light-gray;
                        transition: 0.6s width;
                        @include respond-to(medium){
                            display: none;
                        }
                    }
                    span {
                        position: relative;
                        z-index: 2;
                    }
                    &:hover {
                        text-decoration: none;
                    }
                }
                margin-left: 26px;
                @include respond-to(medium){
                    margin-left: 0;
                    padding-left: 26px;
                    transition: 0.3s background-color;
                }
                &.active,
                &:hover {
                    .dropdown__list__desc {
                        visibility: visible;
                        height: 100%;
                        opacity: 1;
                    }
                    a {
                        &:before {
                        width: calc(50% + 3px);
                        }
                    }
                   
                }
            }
             
            &--red {
                li {
                    &.active,
                    &:hover {
                        border-left: solid 4px $red;
                    }
                }
                
            }
            &--yellow {
                li {
                    &.active,
                    &:hover {
                        border-left: solid 4px $yellow;
                    }
                }
                
            }
            &--green {
                li {
                    &.active,
                    &:hover {
                        border-left: solid 4px $green;
                    }
                }
                
            }
            
            
        }
        .nav__icon {
            height: 28px;
        }
    }
}
    .header__nav {
    @include respond-to(medium){
        position: absolute;
        
        right: 0;
        left: auto;
        top: -20px;
        overflow: auto;
        bottom: 0;
        background-color: $white;
        height: 100vh;
        padding-top: 100px;
        width: 300px;
        transform: translateX(100%);
        transition: 0.3s transform;
        &.active {
            transform: translateX(0%);
        }
        > ul {
            li {
                + li {
                    margin: 0;
                    
                }
                padding: 0;
                @include respond-to(medium){
                    text-align: left;
                    width: 100%;
                    .dropdown__list__desc {
                        display: none;
                    }
                }
                a {
                    padding: 12px 40px;
                }
            }
        }
        .search:not(.search--mobile) {
            display: none;
        }
    }
}
.nav__overlay {
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 99;
    height: 0;
    background-color: rgba(0,0,0,0.2);
    transition: 0.3s opacity, height 0s 0.4s;
}
body.nav__overlay--active {
    .nav__overlay {
        z-index: 98;
        opacity: 1;
        height: 100vh;
        transition: 0.3s 0.1s opacity , height 0s;
    }
}
.header__top__list {
    a {
        border-bottom: solid 4px transparent;
        transition: 0.3s border;
        padding-bottom: 7px;
        &:hover {
            text-decoration: none;
            border-bottom-color: $sky;
        }
    }
    li.active {
        a {
            text-decoration: none;
            border-bottom-color: $sky;
        }
    }
}
@include respond-to(medium){
    .search--mobile  {
        img {
            width: 26px;
        }
    }
    #burger {
        img {
            width: 26px;
            // filter: brightness(0%) invert(100%);
        }
    }
    .page-template-page-single:not(.header-sticky-ready),
    .page-template-page-map:not(.header-sticky-ready) {
        #burger {
            img {
                filter: brightness(0%) invert(100%);
            }
        }   
        .search > a> img {
            filter: brightness(0) invert(1) !important;
        }
    }
}
.logo__dark {
    display: none;
}

.page-template-page-form,
.body--basic-banner {
    .logo__dark {
        display: block;
    }
    .logo__desktop {
        display: none;
    }
    &:not(.header-sticky) .header__nav > ul > li > a, 
    &:not(.header-sticky) .header__nav > ul > li > a em, 
    &:not(.header-sticky) .header__top a {
        color: $dark-gray !important;
    }
    .header__top {
        border-bottom-color: rgba(0,0,0,0.04);
    }
}

.page-template-role,
.page-template-page-map,
.page-template-page-landing  {
    &:not(.header-sticky) .header__nav > ul > li > a,
    &:not(.header-sticky) .header__nav > ul > li > a em,
    &:not(.header-sticky) .header__top a {
        color: #fff !important;
    }
    .header__top {
        border-bottom: 1px solid rgba(255, 255, 255, 0.24);
    }
    .logo__desktop {
        background: url(../img/logo-white.png) top left no-repeat;
        background-size: 100% 100%;
    }
    .logo__desktop img {
        visibility: hidden;
    }
    .search img {
        filter: brightness(0) invert(1);
    }
}
.header-sticky-ready {
    .page-template-page-form {
        .logo__dark {
            display: none;
        }
    }    
}

.page-template-page-single:not(.header-sticky-ready) {
    .search img {
        filter: brightness(0) invert(1);
    }
}
.page-template-page-single.header-sticky-ready {
    .search img {
        filter: brightness(100%) invert(0);
    }
}
@include respond-to(medium){
    body {
        #wrapper {

            .logo__desktop {
                display: block !important;
            }
            .logo__dark {
                display: none !important;
            }
            &:not(.header-sticky) .header__nav > ul > li > a, 
            &:not(.header-sticky) .header__nav > ul > li > a em, 
            &:not(.header-sticky) .header__top a {
                color: $dark-gray !important;
            }
            .header__top {
                border-bottom-color: rgba(0,0,0,0.04) !important;
            }
            
        }
        &.header-sticky-ready {
            #wrapper {
                .logo__desktop {
                    display: none !important;
                }   
            }
        }
    }
}