 .header__top {
     padding: 10px 45px;
     border-bottom: 1px solid rgba(0,0,0,0.04);
     position: relative;
     z-index: 9999;
     // z-index to be over the banner
     &__list {
         display: flex;
         justify-content: flex-end;
         
         li {
             + li {
                 padding-left: 42px;
             }
         }
     }
     @include respond-to(medium){
        display: none;
    }
}
 
.header {
    // padding-top: 23px;
    padding: 23px 45px 0;
    position: relative;
    z-index: 99999;
    transition: 0.6s top;
    @include respond-to(medium){
        padding: 10px;
    }
     // z-index to be over the banner
     .col {
         display: flex;
         justify-content: space-between;
         align-items: center;
         
     }
 }
 .header-sticky-ready {
     .header {
        position: fixed;
        top: -500px;
        background-color: $white;
        width: 100%;
        z-index: 99;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: solid 1px #e5e5e5;
     }
     .banner {
         padding-top: 176px;
     }
 }
 @include respond-to(medium){
     
    .header-sticky-ready {
        .header {
        top: 0;
        }
    }
 }
 .header-sticky {
     .header {
        top: 0;
     }
 }
 .logo__desktop {
    display: inline-block;
 }
 .logo__sticky {
    display: none;
 }
 .header-sticky-ready {
     .logo__dark {
         display: none !important;
     }
    .logo__desktop {
        display: none !important;
    }
    .logo__sticky {
        display: inline-block !important;
     }
 }