.filter__btn {
    cursor: pointer;
}
.filter__box__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 33px;
    font-size: 1.375em;
    font-weight: 600;
    color: $deep-gray;
    .filter__clear {
        font-size: 16px;
        font-weight: 400;
        text-decoration: underline;
        color: $gray;
    }
}
.filter__box {
    .category__list {
        max-height: 320px;
        overflow-y: auto;
        position: relative;
        &:after {
            display: none;
            height: 30px;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background: linear-gradient(180deg, #FFFFFF 9.9%, rgba(255, 255, 255, 0.924855) 33.33%, rgba(255, 255, 255, 0) 100%);
            transform: matrix(1, 0, 0, -1, 0, 0);
            content: " ";
        }
        li {
            padding-left: 30px;
        }
    }
    
    .accordion-item {
        margin-bottom: 20px;
        border: solid 1px $light-gray;
        .accordion-header {
            button {
                background-color: $white;
                &.collapsed {
                    background-color: $light-gray;
                }
                color: $deep-gray;
                font-size: 0.875em;
                font-weight: 600;
                box-shadow: none;
            }
        }
    }
}

.filter__box {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    right: -350px;
    background-color: $white;
    height: auto;
    width: 350px;
    z-index: 99999;
    max-height: 100vh;
    overflow: auto;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.15);
    padding: 33px 13px 16px 17px;
    transition: 0.3s right;
    &.active {
        right: 0;
    }
}