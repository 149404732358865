.tabular__carousel {
    margin-top: 88px;
    &__tabs {
        display: flex;
        align-items: center;
        justify-content: center;
        @include respond-to(medium){
            justify-content: flex-start;
            overflow-x: auto;
            overflow-y: hidden;
            height: 75px;
            .arrow__next,
            .arrow__prev {
                display: none;
            }
            // &::-webkit-scrollbar {
            //     width: 5px;
            //     height: 5px;
            // }
            // &::-webkit-scrollbar-track {
            //     background: transparent;
            // }
            // &::-webkit-scrollbar-thumb {
            //     background: rgba(55,55,55,0.0);
            // }
            // &::-webkit-scrollbar-thumb:hover {
            //     background: rgba(55,55,55,0.4);
            // }
            li {
                white-space: nowrap;
            }   
        }
        li {
            margin: 0 9px;
            position: relative;
            list-style:none !important;
            a {
                display: block;
                padding: 0 16px 10px;
                position: relative;
                background-color: $white;
                z-index: 3;
                text-transform: uppercase;
                font-size: 1.125em;
                text-align: center;
                color: $deep-gray;
                &:hover {
                    text-decoration: none;
                }
            }
            &:before {
                content: " ";
                width: 100%;
                height: 3px;
                left: 0;
                position: absolute;
                background-color: $light-gray;
                top: 100%;
                transition: 0.6s background-color;
                border-radius: 3px;
            }
            &:after {
                content: " ";
                position: absolute;
                left: 50%;
                top: 0;
                border-bottom: 3px solid $red;
                border-right: 3px solid $red;
                width: 20px;
                height: 20px;
                background-color: $white;
                transform: rotate(45deg) translateX(-50%);
                transition: all 0.6s 0.1s;
            }
            &.active {
                &:after {
                    top: calc(100% - 3px);
                }
            }

            &.tab--red {
                &:hover:before,
                &.active:before {
                    background-color: $red;
                }
                &:after {
                    border-color: $red;
                }
            }
            &.tab--yellow {
                &:hover:before,
                &.active:before {
                    background-color: $yellow;
                }
                &:after {
                    border-color: $yellow;
                }
            }
            &.tab--blue {
                &:hover:before,
                &.active:before {
                    background-color: $blue;
                }
                &:after {
                    border-color: $blue;
                }
            }
            &.tab--aqua {
                &:hover:before,
                &.active:before {
                    background-color: $aqua;
                }
                &:after {
                    border-color: $aqua;
                }
            }
            &.tab--purple {
                &:hover:before,
                &.active:before {
                    background-color: $purple;
                }
                &:after {
                    border-color: $purple;
                }
            }
        }
    }

    &__content {
        overflow: hidden;
        visibility: none;
        opacity: 0;
        height: 0;
        position: relative;
        .arrow__prev,
        .arrow__next {
            position: absolute;
            top: 50%;
            opacity: 1;
            width: 20px;
            z-index: 4;
            transform: translateY(calc(-50% - 15px));
            transition: 0.3s all;
            cursor: pointer;
            svg {
                path {
                    stroke: $gray;   
                    opacity: 1 !important;
                }
            }
            &:hover {
                opacity: 1;
            }
            &:hover.red {
                svg path {
                    stroke: $red;
                }
            }
            &:hover.yellow {
                svg path {
                    stroke: $yellow;
                }
            }
            &:hover.blue {
                svg path {
                    stroke: $blue;
                }
            }
            &:hover.purple {
                svg path {
                    stroke: $purple;
                }
            }
        }
        .arrow__prev {
            left: -40px;
        }
        .arrow__next {
            right: -40px;
        }
        &.active {
            margin-top: 34px;
            visibility: visible;
            opacity: 1;
            height: 100%;
            overflow: visible;

            .slick-slide {
                animation-fill-mode: forwards;
                animation-name: fadeInDown;
                opacity: 0;
                animation-duration: 0.5s;
                --animation-delay: 0.2s;
                $var: 10;
                @for $i from 1 through 10 {
                    &:nth-child(#{$i}) {
                      animation-delay: calc(var(--animation-delay) * #{$i});
                    }
                }
            }
            
        }
        .carousel__item {
            background-color: $light-gray;
            padding: 24px 24px 24px 0;
            border-radius: 8px;
            display: inline-flex !important;
            justify-content: space-between;
            flex-direction: column;
            &__icon {
                height: 96px;
                width: 96px;
                min-width: 96px;
                position: relative;
                overflow: hidden;
                border-radius: 100%;
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate3d(-50%, -50%, 0);
                    width: auto;
                    height: 96px;
                    min-width: 100%;

                }
            }
            &__text {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                h5 {   
                    position: relative;
                    padding-left: 23px;
                    font-size: 1.25em;
                    font-weight: 600;
                    small {
                        display: block;
                        margin-top: 15px;
                        font-weight: 400;
                        font-size: 16px;
                    }
                    &:before {
                        content: " ";
                        left: 0;
                        height: 100%;
                        position: absolute;
                        border-radius: 3px;
                        width: 5px;
                    }
                }
                img {
                    border-radius: 100%;
                    max-width: none;
                }
            }
            &__details {
                padding-left: 23px;
                margin-top: 33px;
                font-size: 1em;
                color: $gray;
                li {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                }
                img {
                    width: 15px;
                    margin-right: 9px;
                }
            }
        }
        .tabular__carousel--red {
            .carousel__item__text {
                h5 {
                    &:before {
                        background-color: $red;
                    }
                }
            }
           
        }
        .tabular__carousel--yellow {
            .carousel__item__text {
                h5 {
                    &:before {
                        background-color: $yellow;
                    }
                }
            }
            
        }
        .tabular__carousel--blue {
            .carousel__item__text {
                h5 {
                    &:before {
                        background-color: $blue;
                    }
                }
            }
           
        }
        .tabular__carousel--purple {
            .carousel__item__text {
                h5 {
                    &:before {
                        background-color: $purple;
                    }
                }
            }
          
        }
        .slick-slide {
            margin: 0 11px;
        }
        .slick-list {
            margin: 0 -11px;
        }
    }
}
.carousel__see-more {
    float: right;
    display: inline-block;
    margin-top: 23px;
    color: $deep-gray;
    &.red {
        &:hover {
            color: $red;
        }
        svg path {
            stroke: $red;
        }
    }
    &.yellow {
        &:hover {
            color: $yellow;
        }
        svg path {
            stroke: $yellow;
        }
    }
    &.blue {
        &:hover {
            color: $blue;
        }
        svg path{
            stroke: $blue;
        }
    }
    &:hover {
        text-decoration: none;
        svg {
            animation: arrow-right 4s infinite;
        }
    }
}
@include respond-to(medium){
    .tabular__carousel {
        margin-bottom: 40px; 
    }
    .tabular__carousel__slick {
        div:nth-child(n+6) {
            display: none !important;
        }
        .carousel__item {
            margin-bottom: 20px;
            width: 100%;
        }   
    }
    .tabular__carousel .arrow__next,
    .tabular__carousel .arrow__prev {
        display: none !important;
    }
}

.archive {
    .tabular__carousel {
        margin-top: 20px;
    }
    .tabular__carousel__tabs li {
        width: 25%;
        @include respond-to(medium){
            width: auto;
        }
    }
}