.clients {
    background-color: rgba(63,63,63,0.05);
    margin-top: 115px;
    padding-bottom: 37px;
    .col3__box__bottom {
        a {
            color: $deep-gray;
            transition: 0.3s color;
            &:hover {
                color: $red;
            }
        }
        p {
            font-size: 1.25em;
            color: $gray;
            @include respond-to(medium){
                text-align: center;
            }
            span {
                color: $deep-gray;
                font-weight: 600;
            }
        }
    }
    .client__slick {
        margin-top: 35px;
        &__item {
            text-align: center;
            a {
                display: inline-block;
            }
            img {
                max-height: 90px;
            }
        }
    }
    .client__slider {
        position: relative;
        img {
            filter: saturate(0);
            transition: filter 0.3s;
            &:hover {
                filter: saturate(100%);
            }
        }

        .arrow__prev,
        .arrow__next {
            position: absolute;
            top: 50%;
            margin-top: 20px;
            opacity: 0.2;
            z-index: 4;
            transform: translateY(calc(-50% - 15px));
            transition: 0.3s all;
            cursor: pointer;
            svg {
                path {
                    stroke: $gray;   
                }
            }
            &:hover {
                opacity: 1;
            }
            &:hover.red {
                svg path {
                    stroke: $red;
                }
            }
            &:hover.yellow {
                svg path {
                    stroke: $yellow;
                }
            }
            &:hover.blue {
                svg path {
                    stroke: $blue;
                }
            }
            &:hover.purple {
                svg path {
                    stroke: $purple;
                }
            }
        }
        .arrow__prev {
            left: -20px;
        }
        .arrow__next {
            right: -20px;
        }
    }
}