
.banner__single__full {
    color: $white;
    text-align: center;
    display: flex;
    position: relative;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 80px;
    height: 648px;
    a {
        color: $white;
    } 
    h1 {
        color: $white;
        max-width: 1024px;
        margin: 20px auto;
        @include respond-to(small){
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    p {
        max-width: 800px;
        margin: 20px auto 0;
        font-size: 1.125em;
        @include respond-to(small){
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    &:before {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.55);
    }
    &:after {
        content: " ";
        position: absolute;
        top: 100%;
        margin-top: -43px;
        width: 1px;
        height: 86px;
        background-color: $red;
    }
}

.banner__single {
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 83px;
    &--noline {
        margin-bottom: 20px;
        &:after {
            display: none;
            
        }
    }
    &--center {
        align-items: center !important;
    }
    &--light {
        &:before {
            display: none;
        }
        h1, a {
            color: $dark-gray;
        }
    }
    h1 {
        font-size: 3.125em;
        text-align: center;
        font-weight: 700;
    }
    @include respond-to(medium){
        min-height: 400px;
        background-position: bottom left !important;
        h1 {
            font-size: 2em;
        }

    }
}
.banner__single--bottom {
    align-items: flex-end;
    padding-bottom: 60px;
}