.modal__block {
    margin-bottom: 35px;
    &__box {
        position: relative;
        margin-bottom: 35px;
        text-align: center;
    }
    .modal__image {
        border-radius: 100%;
        overflow: hidden;
        img {
            width: 100%;
        }
    }
    .modal__info {
        position: absolute;
        bottom: -15px;
        left: 50%;
        transform: translateX(-50%);
        width: 38px;
        height: 38px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.26));
        cursor: pointer;
    }
    .modal__text {
        text-align: center;
        color: $deep-gray;
        h4 {
            font-size: 1.25em;
            font-weight: 600;
            margin-bottom: 5px;
        }
        p {
            color: $deep-gray;
        }
    }
}
.modal__overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.1);
    z-index: 999;
    align-items: center;
    justify-content: center;
    @include respond-to(medium){
        bottom: auto;
        overflow: auto;
        min-height: 100vh;
    }
    &.active {
        display: flex;
    }
    &__box {
        background-color: $white;
        max-width: 1000px;
        padding: 50px 50px 50px 100px;
        border-radius: 6px;
        box-shadow: 0 0 10px rgba(0,0,0,0.05);
        color: $deep-gray;
        position: relative;
        @include respond-to(medium){
            padding: 50px;
            max-height: 100vh;
            overflow: scroll;
        }
        a {
            color: $deep-gray;
            &:hover {
                text-decoration: underline;
            }
            img {
                margin-right: 10px;
            }
        }
        h4 {
            font-size: 3.125em;
            font-weight: 700;
        }
        p.position {
            font-size: 1.6875em;
            margin-bottom: 35px;
            clear: both;
            width: 100%;
        }
        p {
            margin-bottom: 30px;
        }
        .modal__description {
            display: flex;
            align-items: start;
            margin-bottom: 40px;
            @include respond-to(medium){
                flex-direction: column;
            }
            > div {
                max-height: 600px;
                overflow: auto;
                @media only screen and (max-height: 993px){
                    max-height: 400px;
                }
            }
        }
        .modal__overlay__image {
            min-width: 220px;
            img {
                width: 100%;
            }
        }
    }
    .modal__close {
        cursor: pointer;
        font-size: 3em;
        font-weight: 100;
        position: absolute;
        top: 50px;
        right: 50px;
        @include respond-to(medium){
            
            right: 20px;
            top: 120px;
        }
    }
    .modal__overlay__image {
        float: right;
    }
}