
  /** Text Animation **/
  
  @-webkit-keyframes fadeInUpSD {
    0% {
      opacity: 0;
      -webkit-transform: translateY(100px);
      transform: translateY(100px);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  @keyframes fadeInUpSD {
    0% {
      opacity: 0;
      -webkit-transform: translateY(100px);
      transform: translateY(100px);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }

  @-webkit-keyframes fade {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  @keyframes fade {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeInDown {
    0% {
      opacity: 0;
      -webkit-transform: translateY(-20px);
      transform: translateY(-20px);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  @-webkit-keyframes fadeInUpSD-exit {
    0% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
    
    100% {
        opacity: 0;
        -webkit-transform: translateY(100px);
        transform: translateY(100px);
    }
  }
  @keyframes fadeInUpSD-exit {
    0% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
    
    100% {
        opacity: 0;
        -webkit-transform: translateY(100px);
        transform: translateY(100px);
    }
  }

  @-webkit-keyframes fadeInRight {
    0% {
      opacity: 0;
      -webkit-transform: translateX(100px);
      transform: translateX(100px);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }

  @keyframes fadeInRight {
    0% {
      opacity: 0;
      -webkit-transform: translateX(100px);
      transform: translateX(100px);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  @-webkit-keyframes fadeInRight-exit {
    0% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
    
    100% {
        opacity: 0;
        -webkit-transform: translateX(100px);
        transform: translateX(100px);
    }
  }
  @keyframes fadeInRight-exit {
    0% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
    
    100% {
        opacity: 0;
        -webkit-transform: translateX(100px);
        transform: translateX(100px);
    }
  }

  @keyframes arrow-right {
    0% {
      transform: translateX(0%);
    }
    30% {
      transform: translateX(3px);
    }
    0% {
      transform: translateX(0%);
    }
  }
  
  .fadeInUpSD {
    -webkit-animation-name: fadeInUpSD;
    animation-name: fadeInUpSD;
  }
  
  
  
  
  /* Media Queries */
// animation for navigation on load