.feed {
    margin-top: 120px;
    &__list {
        display: flex;
        align-items: flex-start;
        margin: 0 -4px;
        &.show {
            .feed__item {
                animation-fill-mode: forwards;
                animation-name: fadeInDown;
                animation-duration: 0.5s;
                --animation-delay: 0.2s;
                $var: 10;
                @for $i from 1 through 10 {
                    &:nth-child(#{$i}) {
                      animation-delay: calc(var(--animation-delay) * #{$i});
                    }
                }
            }
        }
    }

    &__item {
        opacity: 0;
        background-color: $white;
        margin: 0 4px;
        position: relative;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0);
        width: calc(100% / 4);
        padding-bottom: 30px;
        transition: 0.6s 0.3s box-shadow;
        border-radius: 8px;
        &:hover {
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
        }
        > a {
            display: block;
            height: 207px;
            width: 100%;
            position: relative;
        }
        &__thumb {
            object-fit: cover;
            position: absolute;
            height: 100%;
            min-width: 100%;
        }
        &__text {
            padding: 0 31px;
            margin-top: 26px;
            border-left: solid 4px;
            a:hover {
                text-decoration: none;
            }
            h4 {
                font-size: 1.25em;
                font-weight: 600;
            }
            span {
                display: block;
                margin-top: 15px;
                color: $gray;
                font-size: 1em;
            }
            &.red {
                border-left-color: $red; 
            }
            &.yellow {
                border-left-color: $yellow; 
            }
            &.aqua {
                border-left-color: $blue; 
            }
            &.purple {
                border-left-color: $purple; 
            }
        }
    }
    &__label {
        position: absolute;
        top: 5px;
        right: 5px;
        border-radius: 100%;
        background-color: $white;
        z-index: 4;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.inner__feed {
    .feed__item {
        padding-bottom: 31px;
        background-color: #f5f5f5;
        &__cta {
            margin: 48px 34px 0;
            padding: 0 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: $white;
            border-radius: 4px;
            height: 48px;
            transition: 0.3s color, 0.3s background-color;
            svg path {
                transition: 0.3s stroke;
            }
            &:hover {
                text-decoration: none;
                background-color: $gray;
                color: $white;
                svg path {
                    stroke: $white;
                }
            }
        }
    }
    .carousel__see-more {
        color: $deep-gray;
        float: none;
        font-weight: 600;
        transition: 0.3s color;
        svg {
            margin-right: 10px;
        }
        &:hover {
            color: $red;
        }
    }
    
}
@include respond-to(medium){
    .feed__list {
        overflow-x: auto;
        overflow-y: hidden;
        .feed__item {
            min-width: 250px;
            width: 250px;
        }
    }
}

.feed.noanimation {
    .feed__item {
        transition: 0 all !important;
        
        animation-duration: 0s !important;
        animation-delay: 0s !important;

        opacity: 1 !important;
        -webkit-transform: none  !important;
        transform: none  !important;
    }       
}