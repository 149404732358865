.header__tabs {
    margin-top: -65px;
    position: relative;
    z-index: 98;
    
    ul {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        
        overflow-y: hidden;
        overflow-x: auto;
        height: 100px;
        margin-bottom: -20px;
        margin-top: -21px;
        @include respond-to(medium){            
            margin-bottom: -29px;
            margin-top: -11px;
        }
        // &::-webkit-scrollbar {
        //     width: 5px;
        //     height: 5px;
        // }
        // &::-webkit-scrollbar-track {
        //     background: transparent;
        // }
        // &::-webkit-scrollbar-thumb {
        //     background: #888;
        // }
        // &::-webkit-scrollbar-thumb:hover {
        //     background: #555;
        // }

        li {
            height: 59px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 0 25px;
            position: relative;
            background-color: rgba(255,255,255,0.9);
            border-radius: 3px;
            cursor: pointer;
            transition: 0.3s 0.3s border;
            white-space: nowrap;
            + li {
                margin-left: 10px;
            }
            span {
                color: $primary;
                transition: 0.3s color 0.3s;
                position: relative;
                z-index: 2;
                font-weight: 600;
            }
            &:before {
                content: " ";
                position: absolute;
                top: 0;
                width: 100%;
                height: 4px;
                border-radius: 3px 3px 0 0;
                transition: 0.3s 0.31s height, 0.3s 0.3s border-top;
                border-top: solid 4px rgba(0,0,0,0);
            }
            &:after {
                content: " ";
                width: 0; 
                height: 0; 
                border-left: 16px solid transparent;
                border-right: 16px solid transparent;
                border-top-width: 16px;
                border-top-style: solid;
                opacity: 0;
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translate3d(-50%, -16px, 0);
                transition: opacity 0s 0.3s, transform 0.3s;
            }
            &:hover,
            &.active {
                span {
                    color: $white;
                    text-decoration: none;
                    transition: 0.3s color;
                }
                
                &:before {
                    height: 100%;
                    transition: 0.3s height;
                }
            }
            &.active {
                &:after {
                    transform: translate3d(-50%, 0, 0);
                    opacity: 1;
                    transition: opacity 0.1s 0.3s, transform 0.3s 0.3s;
                }
            }
        }
    }
}
.header__tab {
    &--color1 {
        &:before {
            background-color: $red;
            color: $white;
        }
        &:after {
            border-top-color: $red;
        }
        
    }
    &--color2 { 
        &:before {
            background-color: $yellow;
            color: $white;
        }
        &:after {
            border-top-color: $yellow;
        }
    }
    &--color3 {
        &:before {
            background-color: #30A8AF;
            color: $white;
        }
        &:after {
            border-top-color: #30A8AF;
        }
    }
    &--color4 {
        &:before {
            background-color: $deep-purple;
            color: $white;
        }
        &:after {
            border-top-color: $deep-purple;
        }
    }
}
.header__tab {
    &--color1.active:before {
        border-top: solid 4px $deep-red;
    } 
    &--color2.active:before {
        border-top: solid 4px $deep-yellow;
    }
    &--color3.active:before {
        border-top: solid 4px #4FCED7;
    }
    &--color4.active:before {
        border-top: solid 4px $purple;
    }
}
.header__tab--color2:hover,
.header__tab--color2.active {
    span {
        color: $deep-gray !important;
    }
}
.header__box {
    display: flex;
    background-color: $light-gray;
    align-items: center;
    justify-content: space-between;
    padding: 0 25px 0 25px;
    box-sizing: border-box;
    border-radius: 0 0 4px 4px;
    position: relative;
    z-index: 1;
    height: 0;
    visibility: none;
    opacity: 0;
    overflow: hidden;
    transition: opacity 0.3s;
    &.active {
        
        margin-top: 6px;
        transition: opacity 0.3s 0.3s; 
        height: 148px;
        visibility: visible;
        opacity: 1;
    }
    @include respond-to(medium){
        flex-direction: column;
        &.active {
            padding: 30px 20px;
            height: auto;
        }
    }
    &__item {
        box-shadow: 0 0 10px rgba(0,0,0,0);
        transition: 0.3s box-shadow;
        height: calc(100% - 40px);
        cursor: pointer;
        .header__box__item__image {
            overflow: hidden;
            min-width: 118px;
            position: relative;
            @include respond-to(small){
                min-width: 75px;
            }
            img {
                transition: 0.3s transform;
                object-fit: cover;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                height: 100%;
            }
        }
        &:hover {
            box-shadow: 0 0 10px rgba(0,0,0,0.2);
            img {
                transform: scale(1.1);
            }

        }
        &:before,
        &:after {
            height: 25px;
            content: " ";
            display: block;
        }
        @include respond-to(medium){
            + .header__box__item {
                margin-top: 20px;
            }
        }
        background-color: $white;
        width: calc(50% - 10px);
        display: flex;
        border-left: solid 4px;
        border-radius: 2px;
        @include respond-to(medium){
            width: 100%;
        }
        &--red {
            border-color: $red;
        }
        &--yellow {
            border-color: $yellow;
        }
        &--blue {
            border-color: $blue;
        }
        &--purple {
            border-color: $purple;
        }
        img {
            width: 118px;
        }
    }
    &__text {
        padding: 17px 0 0 30px;
        @include respond-to(small) {
            padding: 17px 0 17px 20px;
        }
        p {
            padding: 0 10px 10px 0;
            @include respond-to(small){
                display: none;
            }
        }
    }
}