.btn__container {
    padding: 20px 0;
}
.btn__solid,
.wp-block-button__link {
    min-width: 138px;
    height: 55px;
    padding: 0 20px;
    background: $white;
    box-shadow: 0px 0px 7px rgba(146, 119, 119, 0.25);
    border-radius: 4px;
    color: $primary;
    font-size: 1.125em;
    font-weight: 600;
    border: none;
    border-bottom: solid 3px transparent;
    white-space:nowrap;
    display: inline-flex; 
    justify-content: center;
    align-items: center;

    position: relative;
    transition: color 0.1s;
    @include respond-to(medium){
        
        max-width: 100%;
        display: flex;
        white-space: normal !important;
        text-align: Center;
    }
    * {
        position: relative;
        z-index: 2;
    }
    span {
        position: relative;
        z-index: 2;
        
    }
    &:before {
        z-index: 1;
        content: " ";
        height: 0;
        bottom: 0;
        width: 100%;
        left: 0;
        right: 0;
        position: absolute;
        transition: 0.1s height;
        z-index: 0;
        border-radius: 4px 4px 0 0;
    }
    &:hover {
        color: $white;
        text-decoration: none;
        &:before {
            height: 100%;
        }
        span {
            color: $white;
        }
    }
    &--red,
    &.has-red-background {
        border-bottom-color: $red;
        &:before {
            background-color: $red;
        }
    }
    &--yellow,
    &.has-yellow-background {
        border-bottom-color: $yellow;
        &:before {
            background-color: $yellow;
        }
    }
    &--blue,
    &.has-blue-background {
        border-bottom-color: $blue;
        &:before {
            background-color: $blue;
        }
    }
    &--aqua,
    &.has-aqua-background {
        border-bottom-color: $aqua;
        &:before {
            background-color: $aqua;
        }
    }
    &--purple,
    &.has-purple-background {
        border-bottom-color: $purple;
        &:before {
            background-color: $purple;
        }
    }
}