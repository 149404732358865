.image__tiles {
    margin-top: 50px;
    .tiles {
        > div {
            border-radius: 4px;
            overflow: hidden;
            position: relative;
            display: block;
            cursor: pointer;
            // cursor: pointer;
            // &:after {
            //     content: " ";
            //     position: absolute;
            //     top: 0;
            //     right: 0;
            //     left: 0;
            //     bottom: 0;
            //     background-color: rgba(0,0,0,0);
            //     transition: 0.6s;
            // }
            &:before {
                content: " ";
                position: absolute;
                mix-blend-mode: multiply;
                height: 100%;
                width: 100%;
                bottom: 0;
                opacity: 0;
                transition: opacity 0.3s;
                z-index: 3;
            }
            &:after {
                content: " ";
                position: absolute;
                z-index: 4;
                bottom: 0;
                left: 0;
                right: 0;
                top: 50%;
                background: linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.36) 31%, rgba(255,255,255,0) 100%);;
            }
            .tile__text {
                position: absolute;
                bottom: 25px;
                left: 0;
                right: 0;
                color: $white;
                font-size: 1.5em;
                padding: 8px 0 8px 20px;
                text-shadow: 0 0 0 0;
                z-index: 6;
                transition: 0.3s text-shadow;
                a {
                    color: $white;
                    &:hover {
                        text-decoration: none;
                    }
                }
                &:before {
                    content: " ";
                    height: 100%;
                    width: 4px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    border-radius: 0 4px 4px 0;
                    transition: width 0.3s cubic-bezier(0.19, 1, 0.22, 1);
                    opacity: 1;
                }
            }
            img {
                transition: all 0.6s 0.1s;
            }
            &:hover {
                .tile__text {
                    text-shadow: 0 0 5px rgba(0,0,0,0.5);
                    &:before {
                        width: 0px;
                    }
                }
                img {
                    transform: scale(1.1);
                }
                &:before {
                    opacity: 0.3;
                }
            }
        }
        
        .tile__date {
            position: absolute;
            top: 0;
            right: 0;
            border-radius: 0 4px 0 4px;
            background-color: $aqua;
            color: $black;
            text-align: center;
            padding: 20px;
            font-size: 0.9375em;
            z-index: 9;
            strong {
                display: block;
                font-size: 1em; 
                line-height: 10px;
            }
        }
    }
   img {
       object-fit: cover;
       height: 353px;
       @include respond-to(small) {
            height: 353px !important;
       }
   }
   @include respond-to(small){
       .tiles {
           > div {
                grid-column-start: 1;
                grid-column-end: 14;
           }
            img {
                height: auto;
                width: 100%;
            }
       }
   }
}
.tile--red {
    &:before {
        background-color: $red;
    }
    .tile__text {
        &:before {
            background-color: $red;
        }
    }
}
.tile--yellow {
    &:before {
        background-color: $yellow;
    }
    .tile__text {
        &:before {
            background-color: $yellow;
        }
    }
}
.tile--blue {
    &:before {
        background-color: $blue;
    }
    .tile__text {
        &:before {
            background-color: $blue;
        }
    }
}
.tile--purple {
    &:before {
        background-color: $purple;
    }
    .tile__text {
        &:before {
            background-color: $purple;
        }
    }
}
@include respond-to(small){
    .image__tiles .tiles {
        display: block;
        white-space: nowrap;
        overflow: auto;
        width: auto;
        > div {
            width: 283px;
            display: inline-block;
            height: 323px;
            .tile__text {
                font-size: 14px;
                max-width: 100%;
                white-space: normal;
                line-height: 15px
            }
        }
    }
}