.landing__content {
    opacity: 1;
    height: auto;
    visibility: visible;
    .carousel__item {
        width: 33%;
        @include respond-to(medium){
            width: 100%;
            margin-bottom: 20px;
        }
    }
    .carousel__item__header {
        position: relative;
        &:before {
            content: " ";
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 5px;
            background-color: $red;
            border-radius: 4px;
        }
    }
}
.landing__listings {
    h2 {
        text-indent: 0;
        vertical-align: middle;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        img {
            margin-right: 5px;
        }
    }
}
.banner__form__landing {
    margin: 40px auto 0;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    
    label {
        padding: 5px 0 0 30px;
        font-style: italic;
    }
    input {
        width: 100%;
        border-radius: 4px;
        &:not(:placeholder-shown) {
            border-color: #A0CD42;
        }
    }
    .form-group {
        width: 100%;
        padding: 0 10px;
        text-align: left;
        position: relative;
    }
    @include respond-to(medium){
        flex-direction: column;
        .submit-group {
            padding: 0 10px;
            width: 100%;
            button {
                width: 100%;
            }
        }
        label {
            padding: 5px 20px 10px 20px;
        }
    }
}
.feed__landing {
    margin-bottom: 100px;
}

.ui-autocomplete {
    position: absolute;
    top: 100%;
    background-color: $white;
    box-shadow: 0 0 10px rgba(0,0,0,0.15);
    color: $dark-gray;
    li {
        padding: 5px;
    }
}