.search {
    position: relative;
    &.active {
        .search__popup {
            visibility: visible;
            opacity: 1;
            height: 100%;
        }
    }
    &__popup {
        visibility: hidden;
        opacity: 0;
        height: 0;
        transition: opacity 0.6s 0.1s;
        &:before {
            content: " ";
            position: fixed;
            z-index: 2;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background-color: rgba(0,0,0,0.2);
        }
        &__box {
            position: absolute;
            right: 0;
            top: 100%;
            background-color: $white;
            box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.17);
            z-index: 3;
            padding: 42px 26px 46px;
            width: 520px;
            border-radius: 4px;
            &:before {
                position: absolute;
                bottom: 100%;
                right: 10px;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-bottom: 5px solid $white;
                height: 0;
                width: 0;
                content: " ";
            }
            label {
                font-weight: 600;
                color: $deep-gray;
                margin-bottom: 10px;
                text-align: left;
                width: 100%;
            }
            .form__group {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                input {
                    margin-right: 11px;
                    background-color: $light-gray;
                    border: none;
                    border-radius: 3px;
                    border-bottom: solid 3px $black;
                    width:100%;
                    height: 53px;
                    padding: 0 10px;
                    @include respond-to(medium) {
                        background: url(../img/icon-search.svg) right center no-repeat;
                        background-size: 28px;
                    }
                    &:focus {
                        outline: none;
                    }
                }
            }
        }
        
    }
}

.search__close {
    display: none;
    img {
        filter: brightness(100%) invert(0) !important;
    }
}
@include respond-to(medium){
    .search__popup__box {
        position: fixed;
        right: 0;
        top: 0;
        z-index: 999;
        width: 100%;
        left: 0;
    }
    .search__close {
        display: block;
        position: absolute;
        top: 40px;
        right: 10px;
    }
    .search--mobile {
        label {
            font-size: 1.125em;
            padding-top: 10px;
            margin-bottom: 10px;
        }
        .search__popup__box .form__group {
            flex-direction: column;
            padding: 20px 0;
            button {
                margin-top: 18px;
            }
        }
    }

}

.search__close--desktop {
    position: absolute;
    bottom: calc(100% + 24px);
    right: 1px;
    background: none !important;
    img {
        filter: brightness(0) invert(100%);
    }
}
.search.active {
    > a {
        visibility: hidden;
    }
}