.listing__search {
    padding: 50px 27px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    > * {
        width: 50%;
        @include respond-to(medium){
            width: 100%;
        }
    }
    p {
        font-size: 1.125em;
        color: $deep-gray;
    }
    .listing__search__field { 
        text-align: right;
        
        @include respond-to(medium){
            flex-direction: column;
        }
        input[type=text] {
            background: url(../img/icon-search.svg) no-repeat 95% center;
            background-size: 24px 24px;
            border: none;
            border-bottom: solid 2px $black;
            background-color: $light-gray;
            color: $deep-gray;
            height: 58px;
            padding: 0 20px;
            min-width: 300px;
            &:focus,
            &:active {
                outline: none;
            }
            
            @include respond-to(medium){
                min-width: 100%;
                width: 100%;
                margin: 0 10px;
                margin-left: 0;
            }
        }
        select {
            border: none;
            border-bottom: solid 2px $black;
            background-color: $light-gray !important;
            color: $deep-gray;
            height: 58px;
            padding: 0 20px;
            min-width: 300px;
            &:focus {
                outline: none;
            }
        }
    }
    @include respond-to(medium){
        flex-direction: column;
        p {
            margin-bottom: 20px;
        }
        .listing__search__field {
            width: 100%;
            input[type=text] {
                width: 100%;
            }
        }
    }
}
.listings {
    margin-bottom: 100px;
}
.listing__item {
    background-color: $light-gray;
    overflow: hidden;
    border-radius: 8px;
    @include respond-to(medium){
        grid-column: span 12;
    }
    .listing__image {
        position: relative;
        height: 175px;
        width: 100%;
        overflow: hidden;
        background-color: #eaeaea;
        img {
            position: absolute;
            object-fit: cover;
            top: 0; 
            left: 50%;
            transform: translateX(-50%);
            
            width: auto;
            height: auto;
            max-width: none;
            
            max-height: 150%;
            min-width: 100%;
        }
    }
    
    .listing__headline {
        font-size: 1.25em;
        font-weight: 600;
        color: $black;
        padding: 5px 15px 5px 30px;
        position: relative;
        margin: 20px 0;
        &:before {
            content: " ";
            position: absolute;
            left: 0;
            height: 100%;
            border-radius: 40px;
            background-color: $aqua;
            top: 0;
            width: 5px;
        }
    }
    .listing__details {
        padding: 0 30px 40px;
        ul {
            li {
                margin-bottom: 15px;
                padding-left: 25px;
                &.loc {
                    background: url(../img/icon-loc-small.svg) no-repeat left 5px;
                    background-size: 16px 16px;
                }
                &.time {
                    background: url(../img/icon-clock-small.svg) no-repeat left 5px;
                    background-size: 16px 16px;
                }
                &.person {
                    background: url(../img/icon-person-small.svg) no-repeat left 5px;
                    background-size: 16px 16px;
                }
            }
        }
    }
    &--featured {
        position: relative;
        &:after {
            content: "Featured";
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            background-color: $aqua;
            color: $deep-gray;
            font-size: 500;
            height: 37px;
            width: 112px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px 0 8px 0;

        }
        .listing__details {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            ul, p {
                width: 50%;
            }
            @include respond-to(medium){
                flex-direction: column;
                ul, p {
                    width: 100%;
                }
            }
            ul {
                padding-right: 10px;
            }
        }
    }
}

.tabular__carousel__content {
    .listings {
        .aqua {
            .listing__headline {
                &:before {
                    background-color: $aqua;
                }
            }
            .listing__item--featured {
                &:after {
                    background-color: $aqua;
                }
            }
        }
        .red {
            .listing__headline {
                &:before {
                    background-color: $red;
                }
            }
            .listing__item--featured {
                &:after {
                    background-color: $red;
                }
            }
        }
        .yellow {
            .listing__headline {
                &:before {
                    background-color: $yellow;
                }
            }
            .listing__item--featured {
                &:after {
                    background-color: $yellow;
                }
            }
        }
        .purple {
            .listing__headline {
                &:before {
                    background-color: $purple;
                }
            }
            .listing__item--featured {
                &:after {
                    background-color: $purple;
                }
            }
        }
    }
}
.resource__listing {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .feed__item__cta  {
        margin: 0;
    }
    .listing__headline:before {
        width: 4px;
        border-radius: 4px;
    }
    .listing__details {
        padding-bottom: 20px;
        ul {
            li {
                padding-left: 0;
            }
        }
        
    }
    .listing__headline {
        margin-top: 20px;
    }
}
.news-centre__listing  {
    background-color: transparent;
    transition: 0.3s box-shadow;
    &:hover {
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    }
    .listing__headline:before {
        width: 4px;
        border-radius: 4px;
    }
    .listing__details {
        padding-bottom: 20px;
        ul {
            li {
                padding-left: 0;
            }
        }
        
    }
    .listing__headline {
        margin-top: 20px;
    }
}
.listing__item.red {
    .listing__headline:before {
        background-color: $red;
    }
}
.listing__item.green {
    .listing__headline:before {
        background-color: $green;
    }
}
.listing__item.purple {
    .listing__headline:before {
        background-color: $purple;
    }
}
.listing__item.blue {
    .listing__headline:before {
        background-color: $blue;
    }
}
.listing__item.aqua {
    .listing__headline:before {
        background-color: $aqua;
    }
}
.listing__item.yellow {
    .listing__headline:before {
        background-color: $yellow;
    }
}

.empty-results {
    margin: 20px auto 0;
    width: 100%;
    text-align: center;
    position: absolute;   
}

.search__filter {
    display: flex;
    justify-content: flex-end;
}
.filter__btn {
    height: 58px;
    width: 164px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: $light-gray;
    border-radius: 4px; 
    margin-left: 9px;
    font-weight: 600;
    color: $deep-gray;
    cursor: pointer;
    transition: 0.3s background-color, 0.3s color;
    @include respond-to(medium){
        width: 100%;
        margin-left: 0;
    }
    &:hover {
        background-color: $gray;
        color: $white;
    }
    span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: $white;
        border-radius: 100%;
        padding: 10px;
        margin-right: 15px;
        
        width: 37px;
        height: 39px;
    }
}
.load__container {
    margin-top:30px;
}