.entry-title {
    color: $deep-gray;
    margin-bottom: 10px !important;
    font-size: 18px;
}
.entry-meta {
    display: none;
    margin-bottom: 30px;
    font-style: italic;
    color: $gray;
    span {
        color: $gray;
    }
}
