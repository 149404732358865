.sidebar__content__col {
    padding-left: 30px;
}
.content--no-bottom-padding {
    padding-bottom: 0 !important;

}
.single__content {
    padding-bottom: 122px;
    + .feed {
        margin-bottom: 90px;
        margin-top: 0;
    }
    * {
        word-break: break-word;
    }
    p {
        font-weight: 400;
        color: $deep-gray;
        line-height: 1.5em;

    }
    table {
        border: none !important;
        th {
            border: none;
            border-bottom: solid 3px;
            font-weight: 600;
            color: $deep-gray;
            vertical-align: top !important;
        }
        td {
            border: none;
            border-bottom: solid 1px $deep-gray;
            color: $deep-gray;
            padding: 20px 30px;
        }
    }
    .wp-block-columns {
        p {
            padding-right: 20px;
        }
    }
    .carousel__slick__container {
        position: relative;
        .carousel__slick__arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            opacity: 0.8;
            transition: opacity 0.3s;
            &:hover {
                opacity: 1;
            }
            &.prev {
                left: 0;
            }
            &.next {
                right: 0;
            }
        }
    }
    .wp-block-cb-carousel  {
        padding: 20px 0 0;
        .slick-slide {
            padding: 0;
        }
        .slick-arrow {
            background-size: 100% 100%;
            height: 75px;
            width: 40px;
            z-index: 9;
            &.slick-prev {
                background: url(../img/icon-single-carousel-arrow-prev.png) top left no-repeat; 
                left: 0;
            }
            &.slick-next {
                background: url(../img/icon-single-carousel-arrow-next.png) top left no-repeat; 
                right: -3px;
            }
            &:before {
                display: none;
            }
        } 
    }
   
    .carousel__slick {
        margin-bottom: 1.75em;
        img {
            width: 100%;
        }
        &__item {
            position: relative;
            
        }
        &__text {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 20px;
            background: linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.36) 31%, rgba(255,255,255,0) 100%);


            z-index: 2;
            color: $white;
        }
    }
    .wp-block-quote {
        border-left: solid 4px $deep-blue;
        padding-left: 40px;
        color: $black;
        font-size: 19px;
    }
    .col-8 {
        > p,
        > h2,
        > h3,
        > h4 {
            margin-bottom: 1.75em;
        }
    }
    h2 {
        color: #2697C8;
        font-size: 1.6875em;
        margin: 42px 0 1.75em;
        text-indent: 0;
    }
    h3 {
        font-size: 1.25em;
        margin: 2em 0 1.75em;
    }
    ul {
        li {
            list-style: disc;
            padding: 0 0 10px 10px;
            margin-left: 30px;
            word-break: break-word;
        }
    }
    ul.list-arrow {
        li {
            list-style: none;
            background: url(../img/icon-arrow-right-small.png) left 5px no-repeat;
            padding: 0 0 10px 30px;
        }
    }
    ul.tabular__carousel__tabs {
        li {
            background: none;
            padding: 0;
        }
    }
    .social__list__container {
        ul {
            list-style: none;
            li {
                list-style: none;
                padding: 0 ;
                margin-left: 0;
            }
        }
    }
    ul.slick-dots li {
        background: none !important;
        padding: 0 5px;
        margin: 0;
    }
    .uagb-tab,
    .single__content__sidebar  li {
        list-style: none;
    }
    a:not(.btn__solid) {
        // color: $red;
    }
    
    ol { 
        @for $i from 1 through 100 {
            &[start="#{$i}"] {
                counter-reset: lis $i ;
            }
        }
        counter-reset: item;
        list-style-type: none;
        li {
            padding: 0 0 15px 40px;
            position: relative;
            &:before {
                position: absolute;
                left: 0;
                content: counter(item) "  ";
                counter-increment: item;
                width: 26px;
                height: 26px;
                display: inline-block;
                text-align: center;
                border-radius: 100%;
                border: solid 1px $red;
                margin-right: 20px;
            }
        }  
        
    }
    ol[type="a"] {
        counter-reset: listStyle;
        margin-top: 10px;
        li {
            counter-increment: listStyle;
        }
        li:before {
            content: counter(listStyle, lower-alpha);
        }
    }
    ol ol,
    ul ul {
        margin-left: 20px;
    }
    .wp-block-pullquote {
        background: url(../img/bg-bubble-yellow.png) no-repeat center center;
        background-size: 100% auto !important;
        padding: 0 40px; 
        height: 450px;
        color: $deep-gray;
        display: inline-flex;
        align-items: center;
        @include respond-to(medium){
            padding: 40px 20px 30px; 
            min-height: 320px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
        p {
            font-size: 1.125em;
            padding-bottom: 20px;
            max-width: 70%;
            font-weight: 500;
            font-style: italic;
            margin: 0 auto 20px;
            
            padding-right: 0;
        }
        cite {
            font-style: normal;
            font-size: 0.875em;
        }
    }
    .accordion-item {
        border: none;
        margin-bottom: 12px;
        h4 {
            font-size: 1.6875em;
            padding: 0;
            margin: 0;
            color: $deep-gray;
        }
        .accordion-button {
            background-color: $light-gray;
            color: $deep-gray;
            font-weight: 600;
            box-shadow: 0 0 0 transparent;
            &:not(.collapsed)::after {
                background-image: url(../img/icon-arrow-down.png);
            }
            &::after {
                background-image: url(../img/icon-arrow-up.png);
                background-size: auto 100%;
            }
        }
        .accordion-collapse {
            background-color: $light-gray;
        }
    }

    .social__list {
        margin: 65px 0;
        position: relative;

        text-align: center;
        font-size: 0.9375em;
        &:before {
            content: " ";
            top: 50%;
            transform: translateY(-2px);
            background-color: $gray;
            width: 100%;
            position: absolute;
            left: 0;
            right: 0;
            height: 1px;

            z-index: 1;
        }
        &__container {
            background-color: $white;
            padding: 10px 25px;
            display: inline-flex;
            position: relative;
            align-items: center;
            z-index: 2;
            ul {
                list-style: none;
                padding: 0;
                display: inline-flex;
                align-items: center;
                justify-content: space-between;
                margin-left: 10px;
                li {
                    background: none;
                    padding: 0 5px;
                }
            }
        }
    }
    .col3-image {
        h4 {
            font-size: 2.875em;
            color: $deep-gray;
            margin: 0;
            padding: 0;
            font-weight: 700;
        }
        p {
            color: #717171;
            font-size: 1em;
            padding-right: 0;
        }
    }
    .divider {
        border: none;
        border-bottom: 1px dashed #717171;
        opacity: 0.5;
        background-color: transparent;
        padding: 0;
        margin: 80px 0;
    }

    .donate__container {
        position: relative;
        min-height: 250px;
        padding: 40px 38px;
        margin-top: 50px;
        border-radius: 4px;
        background: url(../img/bg-texture1.png) repeat top left;
        img {
            position: absolute;
            bottom: 0;
            right: 40px;
            z-index: 2;
        }
        p {
            max-width: 40%;
            font-size: 1.25em;
            margin-bottom: 30px;
        }
        .btn__solid {
            min-width: 180px;
            box-shadow: 0px -1px 9px rgba(48, 147, 129, 0.2);
        }
        &--red {
            background-color: rgba(277, 31, 51, 0.06);
            p {
                color: darken($red, 10%);
            }
        }
        &--yellow {
            background-color: lighten($yellow, 45%);
            p {
                color: darken($yellow, 10%);
            }
        }
        &--blue {
            background-color: rgba(79, 206, 215, 0.15);
            p {
                color: $deep-blue;
            }
        }
        &--purple {
            background-color: lighten($purple, 40%);
            p {
                color: darken($purple, 10%);
            }
        }
        &--green {
            background-color: lighten($green, 40%);
            p {
                color: $deep-green;
            }
        }
    }
}
@include respond-to(medium){

    .single__content {
        .row {
            margin: 0;
        }
        .col {
            display: none;
        }
        .col-8 {
            width: 100%;
            flex: 0 0 100%;
            max-width: 100%;
            h2 {
                margin-bottom: 20px;
            }
            h3 {
                margin: 1em 0 0.875em;
            }
        }
        .wp-block-columns {
            margin-bottom: 0;
        }
        .wp-block-column {
            margin-bottom: 20px;
        }
        .wp-block-cb-carousel {
            padding: 20px 0 40px;
        }
        .slick-dots {
            bottom: 10px;
        }
        .donate__container {
            display: flex;
            flex-direction: column;
            padding-bottom: 0;
            img {
                position: relative;
                order: 2;
                margin-top: 40px;
            }
            .btn__solid {
                min-width: 150px;
            }
            p {
                max-width: 100%;
                width: 100%;
            }
        }
    }
    .single__content--showcol  {
        .col {
            display: block;
        }
    }
}
.single__content .single__content > h2:first-child {
    margin-top: 0;
}
.page-template-role {
    .single__content h2 {
        margin: 50px 0 20px !important;
    }
}
.list-unstyled {
    list-style: none !important;
    padding-left: 0;
}

.wp-block-uagb-tabs {
    li {
        background: none !important;
        a {
            margin-top: -10px;
        }
        &:after {
            border-bottom-color: $aqua;
            border-right-color: $aqua;
        }
        &.uagb-tabs__active {
            &:before {
                background-color: $aqua;
            }
        }
    }
    
}
 .wp-block-uagb-tabs .uagb-tabs__panel .uagb-tab span {
     color: $deep-gray !important;
 }
 .uagb-tabs__wrap.uagb-tabs__hstyle1-desktop .uagb-tab {
    padding: 6px 12px 0 !important;
 }
.uagb-tabs__wrap.uagb-tabs__hstyle1-desktop .uagb-tab {
    float: none !important;
    border: none !important;
    
}
.wp-block-uagb-tabs {
    margin: 40px 0;
}
.uagb-block-ef2c58d4 .uagb-tabs__panel .uagb-tab.uagb-tabs__active {
    background-color: transparent;
}
.uagb-tabs__body-container.active {
    display: block;
}
.uagb-block-ef2c58d4 .uagb-tabs__panel .uagb-tab {
    a,
    span {
    color: $deep-gray !important;
        &:hover {
            color: $deep-gray !important;
        }
    }
}
.uagb-tabs__wrap.uagb-tabs__hstyle1-desktop ul.uagb-tabs__panel {
    margin: 0 auto;
}
.uagb-tabs__wrap ul.uagb-tabs__panel li.uagb-tab {
    min-width: 120px;
}
.uagb-block-ef2c58d4 .uagb-tabs__panel .uagb-tab {
    border-top: none;
    border-left: none;
    border-right: none;
}
.uagb-tabs__wrap .uagb-tabs__body-wrap {
    width: 100%;
    padding: 20px;
    border: none !important;
}
.wp-block-uagb-tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    @include respond-to(medium){
        justify-content: flex-start;
        overflow-x: auto;
        overflow-y: hidden;
        height: 75px;
        .arrow__next,
        .arrow__prev {
            display: none;
        }
        // &::-webkit-scrollbar {
        //     width: 5px;
        //     height: 5px;
        // }
        // &::-webkit-scrollbar-track {
        //     background: transparent;
        // }
        // &::-webkit-scrollbar-thumb {
        //     background: rgba(55,55,55,0.0);
        // }
        // &::-webkit-scrollbar-thumb:hover {
        //     background: rgba(55,55,55,0.4);
        // }
        li {
            white-space: nowrap;
        }   
    }
    li {
        margin: 0 9px;
        position: relative;
        
        a {
            display: block;
            
            padding: 0 16px 10px;
            position: relative;
            background-color: $white;
            z-index: 3;
            text-transform: uppercase;
            font-size: 1.125em;
            color: $deep-gray;
            &:hover {
                text-decoration: none;
            }
        }
        &:before {
            content: " ";
            width: 100%;
            height: 3px;
            position: absolute;
            left: 0;
            background-color: $light-gray;
            top: 100%;
            transition: 0.6s background-color;
            border-radius: 3px;
        }
        &:after {
            content: " ";
            position: absolute;
            left: 50%;
            top: 4px;
            border-bottom: 3px solid $aqua;
            border-right: 3px solid $aqua;
            width: 20px;
            height: 20px;
            background-color: $white;
            transform: rotate(45deg) translateX(-50%);
            transition: all 0.6s 0.1s;
        }
        &.uagb-tabs__active {
            &:after {
                top: calc(100% - 1px);
            }
        }
        &:hover:before,
        &.active:before {
            background-color: $blue;
        }
        &:after {
            border-color: $blue;
        }
    }

}


a {
        
    overflow-wrap: break-word;
}