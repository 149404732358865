.banner__style {
    &__red {
        &:after {
            background-color: #FCF2FB;
        }
        + .single__content {
            table {
                th {
                    border-bottom-color: $red;
                }
            }
            h2 {
                color: $red;
            }
            .single__content__sidebar {
                h3 {
                    color: $red;
                }
                ul li.active:before {
                    background-color: $red;
                }
            }
            .wp-block-quote {
                border-left-color: $red;
            }
            ol li:before {
                border-color: $red;
            }
            a {
                &:hover {
                    color: $red;
                }
            }
            .wp-block-pullquote {
                background: url(../img/bg-bubble-red.png) no-repeat center center;
                p {
                    border-bottom: 2px dashed $red;
                }
            }
        }
    } 
    &__aqua {
        &:after {
            background-color: $aqua;
        }
        + .single__content {
            table {
                th {
                    border-bottom-color: $aqua;
                }
            }
            h2 { 
                color: $aqua;
            }
            .single__content__sidebar {
                h3 {
                    color: $aqua;
                }
                ul li.active:before {
                    background-color: $aqua;
                }
            }
            .wp-block-quote {
                border-left-color: $aqua;
            }
            ol li:before {
                border-color: $aqua;
            }
            a {
                &:hover {
                    color: $aqua;
                }
            }
            .wp-block-pullquote {
                background: url(../img/bg-bubble-aqua.png) no-repeat center center;
                p {
                    border-bottom: 2px dashed $aqua;
                }
            }
        }
    }
    &__blue {
        &:after {
            background-color: $blue;
        }
        + .single__content {
            table {
                th {
                    border-bottom-color: $blue;
                }
            }
            h2 { 
                color: $blue;
            }
            .single__content__sidebar {
                h3 {
                    color: $blue;
                }
                ul li.active:before {
                    background-color: $blue;
                }
            }
            .wp-block-quote {
                border-left-color: $blue;
            }
            ol li:before {
                border-color: $blue;
            }
            a {
                &:hover {
                    color: $blue;
                }
            }
            .wp-block-pullquote {
                background: url(../img/bg-bubble-blue.png) no-repeat center center;
                p {
                    border-bottom: 2px dashed $blue;
                }
            }
        }
    }
    &__yellow {
        &:after {
            background-color: $yellow;
        }
        + .single__content {
            table {
                th {
                    border-bottom-color: $yellow;
                }
            }
            h2 {
                color: $deep-yellow;
            }
            .single__content__sidebar {
                h3 {
                    color: $yellow;
                }
                ul li.active:before {
                    background-color: $yellow;
                }
            }
            .wp-block-quote {
                border-left-color: $yellow;
            }
            ol li:before {
                border-color: $yellow;
            }
            a {
                &:hover {
                    color: $yellow;
                }
            }
            .wp-block-pullquote {
                background: url(../img/bg-bubble-yellow.png) no-repeat center center;
                p {
                    border-bottom: 2px dashed $deep-yellow;
                }
            }
        }
    }
    &__green {
        &:after {
            background-color: $green;
        }
        + .single__content {
            table {
                th {
                    border-bottom-color: $green;
                }
            }
            h2 {
                color: $deep-green;
            }
            .single__content__sidebar {
                h3 {
                    color: $green;
                }
                ul li.active:before {
                    background-color: $green;
                }
            }
            .wp-block-quote {
                border-left-color: $green;
            }
            ol li:before {
                border-color: $green;
            }
            a {
                &:hover {
                    color: $green;
                }
            }
            .wp-block-pullquote {
                background: url(../img/bg-bubble-green.png) no-repeat center center;
                p {
                    border-bottom: 2px dashed $deep-green;
                }
            }
        }
    }
    &__purple {
        &:after {
            background-color: $purple;
        }
        + .single__content {
            table {
                th {
                    border-bottom-color: $purple;
                }
            }
            h2 {
                color: $purple;
            }
            .single__content__sidebar {
                h3 {
                    color: $purple;
                }
                ul li.active:before {
                    background-color: $purple;
                }
            }
            .wp-block-quote {
                border-left-color: $purple;
            }
            ol li:before {
                border-color: $purple;
            }
            a {
                &:hover {
                    color: $purple;
                }
            }
            .wp-block-pullquote {
                background: url(../img/bg-bubble-purple.png) no-repeat center center;
                p {
                    border-bottom: 2px dashed $purple;
                }
            }
        }
    }
}