.header-sticky {
    .single__content {
        .single__content__sidebar {
            &.sticky {
                top: 114px;
            }
        }
    }
}
.single__content {
    .single__content__sidebar {
        position: relative;
        padding-left: 0;
        transition: top 0.6s 0.3s;
        &.sticky {
            position: fixed;
            top: 10px;
        }
        &.sticky--bottom {
            position: absolute;
            bottom: 0;
        }
        &::before {
            content: " ";
            left: 0;
            bottom: 0;
            position: absolute;
            top: 0;
            background-color: $light-gray;
            width: 4px;
            border-radius: 4px;
            z-index: 1;
        }
        h3 {
            color: $red;
            text-transform: uppercase;
            font-size: 1em;
            margin-bottom: 15px;
            margin-top: 0;
            
            padding-left: 34px;
        }
        ul {
            list-style: none;
            background: none;
            li {
                background: none;
                padding: 10px 0 10px 34px;
                position: relative;
                margin: -4px 0;
                &:before {
                    position: absolute;
                    left: 0;
                    content: " ";
                    top: 0;
                    bottom: 0; 
                    background-color: transparent;
                    width: 4px;
                    z-index: 3;
                    border-radius: 4px;
                    transition: 0.3s background-color;
                }
                
                &.active {
                    &:before {
                        background-color: $red;
                    }
                }
                a {
                    color: $black;
                }   
            }
        }
    }
}