.map__controls {
    padding: 0 10px 22px 40px;
    border-bottom: solid 1px $light-gray;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .wheelchair__checkbox {
        width: 32px;
        height: 32px;
    }
    h4 {
        font-size: 1.375em;
    }
    p {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        input {
            margin-right: 10px;
        }
    }
    .map__controls__options {
        display: flex;
        justify-content: flex-end;
        span {
            display: inline-block;
            margin-left: 10px;
        }
        .filter__btn {
            margin-left: 30px;
        }
    }
}
.map__content {
    position: relative;
    min-height: calc(100vh - 100px);
    display: flex;
    align-items: flex-start;
    .map__list__container {
        padding: 10px;
        width: 55%;
        max-height: calc(100vh - 100px);
        overflow: auto;
    }
    .load__container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
    }
    .map {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        height:100%;
        min-height:100%;
        width: 45%;
        background-color: $light-gray;
        > div {
            height: 100% !important;
        }
    }
}
.map__item {
    border: solid 1px $light-gray;
    border-radius: 8px;
    padding: 29px;
    position: relative;
    transition: 0.3s box-shadow;
    &:hover {
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.27);
    }
    a:hover {
        text-decoration: none;
    }
    &__header {
        text-indent: 0;
        position: relative;
        color: $black;
        font-size: 1.25em;
        line-height: 1.5em;
        small {
            display: block;
            font-size: 18px;
        }
        &:before {
            content: " ";
            top: 0;
            bottom: 0;
            left: -29px;
            width: 4px;
            position: absolute;
            background-color: $gray;
        }
    }
    &__details {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        p {
            width: 60%;
        }
        ul {
            width: 35%;
            margin-left: 5%;
            li {
                + li {
                    margin-top: 10px;
                }
            }
        }
    }

    + .map__item {
        margin-top: 13px;
    }
    &.new {
        h2 {
            &:before {
                background-color: $gray;
            }
        }
        &:before {
            content: "New";
            position: absolute;
            top: 0;
            z-index: 9;
            right: 0; 
            width: 72px;
            height: 37px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $light-gray;
            color: $deep-gray;
            font-size: 1em;
            font-weight: 600;
            border-radius: 0 8px 0 8px;
        }
    }
    &.featured {
       
        h2 {
            &:before {
                background-color: $red;
            }
        }
        &:before {
            content: "Featured";
            position: absolute;
            top: 0;
            z-index: 9;
            right: 0; 
            width: 112px;
            height: 37px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(227, 31, 51, 0.13);
            color: $red;
            font-size: 1em;
            font-weight: 500;
            border-radius: 0 8px 0 8px;
        }
        
    }
}
@include respond-to(medium){
    .map__controls {
        flex-direction: column;
        .map__controls__options {
            padding-top: 20px;
            p span {
                display: none; 
            }
        }
    }
    .page-template-page-map,
    .page-template-page-landing {
        .banner__single {
            height: auto;
        }
    }
    .map__content {
        flex-direction: column;
        .map__list__container {
            width: 100%;
        }
        .map {
            position: static;
            height: 500px;
            width: 100%;
        }
    }
}
@include respond-to(small){
    .map__item__details {
        flex-direction: column;
        p, ul {
            width: 100%;
        }
        ul {
            margin: 40px 0 30px;
        }
    }
}


.gmap-infobox {
    
    font-family: 'Poppins', sans-serif;
}
.gmap-infobox__wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
}
.gmap-infobox__wrapper__content {
    p {
        display: none;
    }
}
.gmap-infobox__wrapper__aside {
    width: 100%;
    ul {
        font-size: 16px;
        margin-bottom: 20px;
    }
}
.gmap-infobox__wrapper__content {
    a.btn {
        min-width: 138px;
        height: 55px;
        padding: 0 10px;
        background: #ffffff;
        -webkit-box-shadow: 0px 0px 7px rgba(146,119,119,0.25);
        box-shadow: 0px 0px 7px rgba(146,11,119,0.25);
        border-radius: 4px;
        color: #000000;
        font-weight: 600;
        border: none;
        border-bottom: solid 3px transparent;
        white-space: nowrap;
        display: inline-flex !important;
        justify-content: center;
        align-items: center;
        position: relative;
        transition: color 0.3s, background-color 0.3s;
        font-size: 16px !important;
        &:hover {
            background-color: $red !important;
            color: $white;
        }
    }
}
.gmap-infobox__wrapper__aside__organisation-image {
    display: none;
}
.gmap-infobox__header {
    h2 {
        font-size: 20px !important;
        font-family: 'Poppins', sans-serif;
        text-indent: 0;
        margin-left: -16px !important;
        padding-left: 15px;
        border-left: solid 4px $gray;
    }
}
.gmap-infobox__wrapper__aside {
    width: 100%;
    min-width: 100%;
    margin-bottom: 15px;
}