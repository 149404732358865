.header__box__form {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    .header__box__field {
        width: calc( ( 100% / 2 ) - ( 150px / 2) );
        
        box-sizing: border-box;
        + .header__box__field {
            margin-left: 22px;
        }
        
       
        label {
            font-size: 0.875em;
            color: $gray;
            font-style: italic;
            padding: 6px 0 0 24px;
        }
       
    }
    .header__box__button {
        margin-left: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        
    }
    @include respond-to(medium){
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .header__box__field {
            width: 100%;
            + .header__box__field {
                margin-left: 0;
            }
        }
        .header__box__button {
            width: 100%;
            margin: 0;
            margin-top: 20px;
            button {
                width: 100%;
            }
        }
    }
}

input[type=text], 
input[type=tel], 
input[type=password], 
input[type=email], 
input[type=number],  
select,
textarea {
    padding: 0 24px;
    background-color: $white;
    color: $primary;
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 22px auto;
    border: none;
    border-bottom: solid 3px $tertiary;
    width: 100%;
    height: 55px;
    max-width: 100%;
    
    &.bg__icon--search{
        background-image: url(../img/icon-search.svg);
    }
    &.bg__icon--pin {
        background-image: url(../img/icon-pin.svg);
    }
    &:focus {
        outline: none;
    }
}
.bg__icon--pin {
    input {
        background-position: right 10px center;
        background-repeat: no-repeat;
        background-size: 22px auto;
        background-image: url(../img/icon-pin.svg);
    }
}
textarea {
    padding: 20px 24px;
    min-height: 200px;
    resize: auto;
}
select {
    padding: 0 24px;
    background-color: $white;
    color: $primary;
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 22px auto;
    border: none;
    border-bottom: solid 3px $tertiary;
    width: 100%;
    height: 55px;
    -webkit-appearance: none;
    background-color: $white !important;
    background: url(../img/icon-chevron-down-black.svg) 95% 25px no-repeat;
}


// duplicated styling to overrule wpforms css
body #wrapper div.wpforms-container-full .wpforms-form {
    
    input[type=text], 
    input[type=password], 
    input[type=tel], 
    input[type=email], 
    input[type=number], 
    select,
    textarea {
        padding: 0 24px;
        background-color: $light-gray;
        color: $primary;
        background-position: right 10px center;
        background-repeat: no-repeat;
        background-size: 22px auto;
        border: none;
        border-bottom: solid 3px $tertiary;
        width: 100%;
        height: 55px;
        max-width: 100%;
        
        &.bg__icon--search{
            background-image: url(../img/icon-search.svg);
        }
        &.bg__icon--pin {
            background-image: url(../img/icon-pin.svg);
        }
        &:focus {
            outline: none;
        }
    }
    .wpforms-submit  {
        position: relative;
        border-radius: 4px;
        background-color: #fff;
        box-shadow: 0px 0px 7px rgba(146, 119, 119, 0.25);
        border:none;
        transition: 0.3s background-color;
        &.btn__solid--yellow {
            &:hover {
                background-color: $yellow;
            }
        }
        &.btn__solid--green {
            &:hover {
                background-color: $green;
            }
        }
        &.btn__solid--aqua {
            &:hover {
                background-color: $aqua;
            }
        }
        &.btn__solid--blue {
            &:hover {
                background-color: $blue;
            }
        }
        &.btn__solid--red {
            &:hover {
                background-color: $red;
            }
        }
        &.btn__solid--purple {
            &:hover {
                background-color: $purple;
            }
        }
        
        &:before {
            display: none !important;
        }
    }
    .wpforms-field-label {
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 10px;
        // height: 70px;
        height: auto;
    }
    .bg__icon--pin {
        input {
            background-position: right 10px center;
            background-repeat: no-repeat;
            background-size: 22px auto;
            background-image: url(../img/icon-pin.svg);
        }
    }
    textarea {
        padding: 20px 24px;
        min-height: 200px;
        resize: auto;
    }
    select {
        padding: 0 24px;
        color: $primary;
        background-position: right 10px center;
        background-repeat: no-repeat;
        background-size: 22px auto;
        border: none;
        border-bottom: solid 3px $tertiary;
        width: 100%;
        height: 55px;
        -webkit-appearance: none;
        background-color: $light-gray !important;
        background: url(../img/icon-chevron-down-black.svg) 95% 25px no-repeat;
    }
   
}