// Use this file to overwrite the basic Bootstrap variables and add your own variables
// To overwrite a Bootstrap variable you don´t have to touch the Bootstrap folder. 
// Just copy a variable from /sass/bootstrap/_variables.scss, paste it here and edit the value.
// As example you find one of the most important Bootstrap variable below: $brand-primary.
// Thats the ugly Bootstrap blue used for all the links, primary buttons etc.
// Just add your color code here, compile the theme.scss file to change this color everywhere
//
// Color system
//
$red: #E31F33;
$yellow: #EBC914;
$blue: #37BAF2;
$aqua: #4fced7;
$purple: #D951D3;
$sky: #37BAF2;
$green: #A0CD42;
$deep-red: #C81B2C;
$deep-aqua: #2697C8;
$deep-blue: #2697C8;
$deep-yellow: #9D860A;
$deep-purple: #B334AD;
$deep-green: #7EA826;
$primary: #000000; //pink
$secondary: #c7c7c7; //eggplant light
$tertiary: #4a4a4a; //light grey
$gray: #787878;
$gray2: #555555;
$light-gray: #f5f5f5;
$lighter-gray: #f9f9f9;
$deep-gray: #414141;
$dark-gray: #3f3f3f;
$white: #ffffff;
$pink: #FFF4F4;
// Font Family
$header-font-family: "Poppins";
$base-font-family: "Poppins";
// Font Weights
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-black: 800;
// Base Font for HTML
$base-font-size: 16px;
// Paragraph Styles
$paragraph-color: #414141;
$paragraph-size: 1rem;
$paragraph-line-height: 1.813rem;
$paragraph-margin: 20px;
$body-font: $base-font-family;
// Header Styles
$header-size: 2.438rem;
$header-line-height: 1rem;
$header-margin: $paragraph-margin * 1.2;
// Links
$link-color: $primary;


// responsive
$breakpoints: (
'small':  767px,
'medium': 1024px,
'large':  1200px
) !default;

@mixin respond-to($breakpoint) {
    // If the key exists in the map
    @if map-has-key($breakpoints, $breakpoint) {
        // Prints a media query based on the value
        @media (max-width: map-get($breakpoints, $breakpoint)) {
        @content;
        }
    }
    
    // If the key doesn't exist in the map
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
            + "Available breakpoints are: #{map-keys($breakpoints)}.";
    }
}

