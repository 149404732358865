.story {
    margin-top: 90px;
    @include respond-to(medium){
        margin-top: 40px;
    }
    &__box {
        position: relative;
        .story__img {
            width: 52%;
            top: 40px;
            overflow: hidden;
            position: relative;
            height: 448px;
            border-radius: 8px;
            > img {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                width: auto;
                height: auto;
                min-width: 100%;
            }
            @include respond-to(small){
                height: 300px;
            }
        }
        &.show {
            .story__img {
                animation-fill-mode: forwards;
                animation-name: fadeInUpSD;
                opacity: 0;
                animation-duration: 1s;
                animation-delay: 0.7s;
            }
            .story__text {
                animation-fill-mode: forwards;
                animation-name: fadeInDown;
                opacity: 0;
                animation-duration: 1s;
                animation-delay: 0.5s;
            }
        }
    }

                
    &__text {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 7%;
        width: 52%;
        padding: 76px 92px;
        border-radius: 8px;
        background: url(../img/bg-texture2.png) top left repeat $pink;
        transition: 0.6s background-color, 0.6s box-shadow, 0.6s transform;
        transform: translateY(0);
        box-shadow: 0 0 0 rgba(0,0,0,0);

        &:hover {
            transform: translateY(20px);
            background-color: $white;
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
        }
        h4 {
            font-size: 1.875em;
            color: $red;
            font-weight: 700;
            margin-bottom: 10px;
        }
        > p:first-of-type {
            font-size: 1.4375em;
            color: $black;
            margin-bottom: 15px;
        }
        p:not(:first-of-type) {
            color: $deep-gray;
            font-size: 1em;
        }
    }
    &__link {
        position: absolute;
        top: 100%;
        right: 20px;
        display: inline-block;
        padding-top: 10px;
        color: $deep-gray;
        &:hover {
            color: $red;
            text-decoration: none;
        }
    }

}
@include respond-to(medium){
    .story__text {
        position: relative;
        left: -15px;
        width: 100%;
        padding: 50px 30px;
    }
    .story__box  {
        .story__img {
            position: relative;
            width: 100%;
            right: -15px;
        }
    }
}
.basic__story {
    margin-top: 10px;
    .story__img {
        height: 260px;
        margin-bottom: 60px;
        border-radius: 8px;
    }
    .story__text {
        padding: 30px;
        height: 260px;
        h4 {
            font-size: 24px;
        }
        p:first-of-type {
            font-size: 1em;
            font-weight: 600;
            margin-bottom: 0;
        }

    }
}