.banner {
    padding-top: 151px;
    margin-top: -151px;
    // pull banner up to overlap with header
    transition: 0.6s background-color;
    // background: url(../img/bg-texture.png) top left repeat;
    position: relative;
    &:after {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 9;
        transition: 0.6s background-color;
        background: url(../img/bg-texture.png) top left repeat;
    }
}
.homepage__banner {
    position: relative;
    overflow: hidden;
    @include respond-to(small){
        max-height: 75vh;
    }
    &.slick-slider {
        margin-bottom: 0;
    }
    .slick__item {
        height: 600px;
        position: relative;
        @include respond-to(medium){
            height: 550px;
        }
        > div {
            height: 100%;
            position: absolute;
        }
        .banner__text {
            // left: 20px;
            top: 0;
            bottom: 0;
            // padding-left: 45px;
            // width: 50%;
            // min-width: 1140px;
            margin: 0 auto;
            z-index: 2;
            // position: relative;
            display: flex; 
            align-items: flex-start;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            .container {
                width: 100%;
                > * {
                    width: 40%;
                }
            }
            h1 {
                font-size: 50px;
                font-weight: 700;
                margin-bottom: 29px;
            }
            p {
                font-size: 1em;
            }
            @include respond-to(medium){
                width: 100%;
                min-width: 100%;
                padding: 60px 20px 20px;
                text-align: center;
                align-items: center;
                justify-content: flex-start;
                .container > * {
                    width: 100%;
                }
                h1 {
                    font-size: 1.875em;
                    padding-top: 40px;
                    width: 80%;
                }
                p {
                    display: none;
                }
            }
        }
    }
    
    .banner__images {
        right: 0;
        bottom: -7px;
        width: 70%;
        background: url(../img/bg-shape1.png) no-repeat 40% bottom;
        background-size: 500px auto;
        &.blue {
            background: url(../img/bg-shape1.png) no-repeat 40% bottom;
        }
        &.yellow {
            background: url(../img/bg-shape-yellow.png) no-repeat 40% bottom;
        }
        &.aqua {
            background: url(../img/bg-shape-aqua.png) no-repeat 40% bottom;
        }
        &.purple {
            background: url(../img/bg-shape2.png) no-repeat 40% bottom;
        }
        &.green {
            background: url(../img/bg-shape-green.png) no-repeat 40% bottom;
        }
        > img {
            position: absolute;
            display: block;
        }
        &__main {
            bottom: 0px;
            left: 30%;

        }
        
        &__obj1 {
            top: 60px;
            left: 20%;
        }
        &__obj2 {
            bottom: -100px;
            left: 35%;
        }
        &__obj3 {
            bottom: 0;
            left: calc(30% + 300px);
        }
        &__obj4 {
            top: -20px;
        }
        @include respond-to(medium){
            width: 100%;
            background-size: cover;
            background-position: center bottom;
            &__main {
                
                left: 50% !important;
                transform: translateX(-50%) !important;
                // max-height: 300px;
                height: 320px;
            }
            &__obj1 {
                top: auto;
                bottom: 0;
                left: 20px;
            }
        }
        @include respond-to(small){
            &__main {
                height: auto;
            }
        }
    }  
}
.archive .homepage__banner {
    .slick__item {
        height: 500px;
    }
}
.homepage__banner {
    .slick-dots {
        position: absolute;
        top: 0;
        right: 45px;
        left: auto;
        bottom: auto;
        text-align: right;
        li {
            width: 12px;
            padding: 0;
            margin: 0;
            &.slick-active {
                button:before {
                    color: $red;
                }
            }
        }
    }
}


.slick-slide:not(.slick-active) {
    visibility: hidden;
    .banner__text {
      width: 100%;
      animation-name: fadeInUpSD-exit;
      animation-duration: 1s;
      animation-delay: 1.1s;
      opacity: 0;
      animation-fill-mode: forwards;
      padding: 10px 20px 30px 0;
    }
    .banner__images {
      > img {
          animation-fill-mode: forwards;
      }
      &__main {
          animation-name: fadeInRight-exit;
          animation-duration: 1s;
          animation-delay: 0.2s;
          opacity: 0;
      }
      &__obj1 {
          animation-name: fadeInUpSD-exit;
          animation-duration: 1s;
          animation-delay: 0.5s;
          opacity: 0;
      }
      &__obj2 {
          animation-name: fadeInUpSD-exit;
          animation-duration: 1s;
          animation-delay: 0.7s;
          opacity: 0;
      }
      &__obj3 {
          animation-name: fadeInUpSD-exit;
          animation-duration: 1s;
          animation-delay: 0.8s;
          opacity: 0;
      }
      
  }
}
.slick-slide.slick-active {
    visibility: visible;
  .banner__text {
      animation-name: fadeInUpSD;
      animation-duration: 1s;
      animation-delay: 1.1s;
      opacity: 0;
      animation-fill-mode: forwards;
      padding: 10px 20px 30px 20px;
      width: 100%;
    }
  .banner__images {
      > img {
          animation-fill-mode: forwards;
      }
      &__main {
          animation-name: fade;
          animation-duration: 1s;
          animation-delay: 0.2s;
          opacity: 0;
      }
      &__obj1 {
          animation-name: fade;
          animation-duration: 1s;
          animation-delay: 0.5s;
          opacity: 0;
      }
      &__obj2 {
          animation-name: fade;
          animation-duration: 1s;
          animation-delay: 0.7s;
          opacity: 0;
      }
      &__obj3 {
          animation-name: fade;
          animation-duration: 1s;
          animation-delay: 0.8s;
          opacity: 0;
      }
      
  }
} 

.page-template-page-home  {
    @media only screen and (min-width: 767px) and (max-width: 1024px){
        .slick-slide {
            &:first-of-type {
                .banner__images {
                    &__main {                  
                        width: auto;
                        left: 50%;
                        height: 400px;
                        transform: translateX(-50%);
                    }
                    &__obj1 {
                        max-width: none;                        
                        top: 143px;
                        left: 14vw; 
                        width: auto;    
                    }
                }
            }
            &:nth-of-type(2) {
                .banner__images {
                    &__main {                    
                        max-width: none;
                        width: auto;
                        left: 50%;
                        height: 400px;
                        transform: translateX(-50%);
                    }
                    &__obj3 {
                        height: 300px;
                    }
                }
            }
            &:nth-of-type(3) {
                .banner__images {
                    &__main { 
                        
                        left: 50%;
                        transform: translateX(-50%);      
                    }
                    &__obj3 {
                        bottom: -120px;
                        left: 60%;
                    }
                }
            }
            &:nth-of-type(4) {
                .banner__images {
                    &__main {       
                        width: auto;
                        max-width: none;
                        height: 400px;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                    &__obj1 {
                        top: auto;
                        bottom: 0;
                        left: 50%;
                    }
                }
            }
        }
    }
}
.page-template-page-home  {
    @include respond-to(small) {
        .slick-slide {
            &:first-of-type {
                .banner__images {
                    &__main {
                        width: 90%;
                        left: 5%;
                    }
                    &__obj1 {
                        max-width: 170%;                        
                        top: 143px;
                        left: 14vw; 
                        width: 170%;    
                    }
                }
            }
            &:nth-of-type(2) {
                .banner__images {
                    &__main {                    
                        max-width: none;
                        width: 120%;
                        left: -5%;
                    }
                    &__obj3 {

                    }
                }
            }
            &:nth-of-type(3) {
                .banner__images {
                    &__main {       
                    }
                    &__obj3 {
                        bottom: -90px;
                        left: 66%;
                    }
                }
            }
            &:nth-of-type(4) {
                .banner__images {
                    &__main {       
                        width: 120%;
                        max-width: 120%;
                        left: -17%;
                    }
                    &__obj3 {
                        left: 14%;
                        bottom: 32px;
                        width: 200%;
                        max-width: 200%;
                    }
                }
            }
        }
    }
}
.post-type-archive-resources  {
    .banner__images__main {
        max-height: 450px;
    }
}