html {
    margin-top: 0 !important;
}
body {
    font-family: $base-font-family;
    font-size: 100%;
    line-height: 1.5em;
    overflow-x: hidden;
}
.container-full {
    width: 100%;
    margin: 0 auto;
}
img {
    max-width: 100%;
}
h2 {
    font-size: 1.6875em;
    color: $deep-gray;
    margin-bottom: 30px;
    // text-indent: 26px;
}
.center {
    text-align: center;
}
#wrapper {
    width: 100%;
    overflow-x: hidden;
}
@include respond-to(medium){
    .alignright  {
        float: none !important;
    }
}
.list-unstyled {
    li {
        list-style: none !important;
        padding-left: 0;
    }
}
@media (min-width: 1200px) {
    .container {
       max-width: 1170px; 
    }
}