footer {
    background-color: $dark-gray;
    color: $white;
    padding: 77px 0 34px;
    // margin-top: 60px;
    .footer__top {
        display: flex;
        width: 100%;
        align-items: flex-start;
        justify-content: space-between;
        
        > p {
            width: 33%;
            font-weight: 300;
            font-size: 0.9375em;
            strong {
                font-weight: 600;
            }
        }
        
        .footer__badge {
            margin: 0 -20px;
            img {
                filter: saturate(0);
                margin: 0 20px;
                transition: 0.6s filter;
                max-width: 200px;
                cursor: pointer;
                &:hover {
                    filter: saturate(100%);
                }
            }
        }
        .footer__social {
            p {
                text-align: right;
                font-weight: 300;
                font-size: 1em;
                margin-bottom: 20px;
            }
            ul {
                display: flex;
                margin: 0 -5px;
                li {
                    margin: 0 5px;
                    
                    a {
                        display: block;
                        background-color: $gray2;
                        border-radius: 100%;
                        width: 46px;
                        height: 46px;
                        position: relative;
                        transition: 0.6s background-color;
                        &:hover {
                            background-color: $black;
                        }
                    }
                    img {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate3d(-50%, -50%, 0);
                    }
                }
            }
        }
        @include respond-to(medium){
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            p {
                width: 100%;
            }
            > * {
                margin-bottom: 20px;
            }
            .footer__social {
                padding-top: 30px;
                p {
                    text-align: center;
                }
            }
        }
    }
    .footer__bottom {
        margin-top: 50px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        .flags {
            margin-right: 24px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0 44px 0 -2px;
            img {
                margin: 0 2px;
            }
        }
        > p {
            width: 100%;
            font-weight: 300;
            font-size: 0.875em;
        }
    }
    .footer__links {
        border-top: solid 1px $black;
        margin-top: 25px;
        padding-top: 25px;
        padding-bottom: 40px;
        text-align: center;
        ul {
            display: flex;
            align-items: center;
            justify-content: center;
            li {
                margin: 0 20px;
                font-size: 0.875em;
                a {
                    color: $white;
                }
            }
        }
    }
}
.footer {
    @include respond-to(medium){
        padding-left: 15px;
        padding-right: 15px;
        .col {
            display: flex;
            flex-direction: column;
        }
        .footer__links {
            order: 1;
            border-top: none;
            margin-top: 10px;
            padding: 10px;
            ul {
                flex-wrap: wrap;
                li {
                    margin: 10px;
                }
            }
        }
        .footer__social {
            p {
                margin-bottom: 10px;
            }
        }
        .footer__bottom {
            order: 2;
            flex-direction: column;
            align-items: center;
            margin-top: 10px;
            
            .flags {
                margin: 0 auto 20px;
            }
            p {
                text-align: center;
            }
        }
    }
}