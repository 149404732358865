.col3__boxes,
.col3__box {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @include respond-to(medium){
        text-align: center;
        align-items: center;
    }
}

.col3__box {
    background-color: $white;
    width: calc(100% / 3);
    justify-content: flex-start;
    @include respond-to(small){
        width: 100%;
    }
    img {
        margin-right: 17px;
        @include respond-to(medium){
            margin: 0 auto 17px;
        }
    }
    
    
    &:first-child {
        img {
            width: 92px;
        }
    }
    &:nth-child(2) {
        img {
            width: 151px;
            height: 87px;
        }
    }
    &:nth-child(3) {
        img {
            height: 99px;
        }
    }
    h3 {
        font-size: 2.875em;
        color: $deep-gray;
        font-weight: 700;
    }
    p {
        font-size: 1em;
        color: $gray;
    }
}
.col3__box__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 41px;
    @include respond-to(medium){
        flex-direction: column;
    }
    a {
        color: $deep-gray;
        text-decoration: none;
        &:hover {
            color: $red;
        }
    }
    p {
        color: $black;
        span {
            color: $gray;
        }
    }
}

@include respond-to(medium) {
    .col3__box__bottom {
        text-align: align;
        justify-content: center;
        a {
            margin-top: 10px;
        }
        
    }
    .col3__boxes {
        .slick-dots {
            width: 80%;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
.power-title {
    margin: 80px 0 50px;
    text-indent: 0;
}

.power__carousel {
    .slick-dots {
        li {
            margin: 0;
            button:before {
                font-size: 8px;
                color: $deep-gray;
            }
            &.slick-active {
                button:before {
                    color: $red;
                }
            }
        }
    }
    .slick-next {
        right: 5px !important;
        &:before {
            color: transparent;
            background: url(../img/icon-chevron-right.png) no-repeat center center;
            height: 17px;
            width: 10px;
        }
    }
    .slick-prev {
        left: 5px !important;
        z-index: 2;
        &:before {
            color: transparent;
            background: url(../img/icon-chevron-left.png) no-repeat center center;
            height: 17px;
            width: 10px;
        }
    }
}