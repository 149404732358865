.paginationjs-pages {
    ul {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 20px;
        li {
            a {
                border-radius: 100%;
                display: flex;
                
                height: 40px;
                width: 40px;
                justify-content: center;
                align-items: center;
                font-weight: bold;
                color: $gray;
                margin-right: 10px;

            }
           
            &:not(.disabled) {
                a {

                    border: solid 2px $light-gray;
                    
                }
            }
            &.active {
                a {
                    color: $deep-gray;
                    border-color: $red !important;
                }
            }
        }
        .paginationjs-next {
            a {
                color: transparent;
                background: url(../img/icon-chevron-right.png) no-repeat center center;
                background-size: 7px 15px;
            }
        }
        .paginationjs-prev {
            a {
                color: transparent;
                background: url(../img/icon-chevron-left.png) no-repeat center center;
                background-size: 7px 15px;
            }
        }
    }
}
ul.pagination {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
    li {
        a, span {
            border-radius: 100% !important;
            display: flex;
            
            height: 40px;
            width: 40px;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            color: $gray;
            margin-right: 10px;
            background-color: transparent !important;

        }
       
        &:not(.disabled) {
            a, span {

                border: solid 2px $light-gray;
                
            }
        }
        &.active {
            a, span {
                color: $deep-gray !important;
                background-color: transparent !important;
                border-color: $red !important;
            }
        }
    }
    .page-link.next {
        a, span {
            color: transparent;
            background: url(../img/icon-chevron-right.png) no-repeat center center;
            background-size: 7px 15px;
        }
    }
    .page-link.prev {
        a, span {
            color: transparent;
            background: url(../img/icon-chevron-left.png) no-repeat center center;
            background-size: 7px 15px;
        }
    }
}